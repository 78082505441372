




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import { DateTime } from 'luxon';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
import { v4 as uuidv4 } from 'uuid';
import proceduresModule from '@/store/modules/proceduresModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    RigSchedule: () => getComponent('schedule/RigSchedule'),
  },
})
export default class Schedule extends Vue {
  rigOptionsOpen = false;

  usernameOptionsOpen = false;

  filterOptionsOpen = false;

  dataLoading = false

  isSavingTasqCompletion = false

  filterByUsernameOption = ''

  isDeletingRigLoadingView = false

  // Add new job
  addNewJobPopupShowing = false;

  newJobName = ''

  newJobEnterJobSummary = ''

  newJobStartDate = ''

  newJobEndDate = ''

  newJobOperator = ''

  newJobExecutedBy = ''

  newJobSelectProcedure = ''

  newJobRigID = ''

  newJobDate: any[] = []

  addJobShowPreTasqMainContent = false

  addJobShowPostTasqMainContent = false

  addJobShowScheduleJobContent = false

  addJobShowScheduleJobPrePostTasqListContent = false

  showAddNewJobPreTasqsTemplateSelection = false

  showAddNewJobPostTasqsTemplateSelection = false

  addJobShowAddNewPretasqContent = false

  addJobShowAddNewPosttasqContent = false

  showAddScheduledJobCompletePreTasqPopupTitle = ''

  showAddScheduledJobCompletePreTasqPopupSummary = ''

  showAddScheduledJobCompletePreTasqPopupAssignee = ''

  showAddScheduledJobCompletePreTasqPopupDeadline = ''

  showAddScheduledJobCompletePostTasqPopupTitle = ''

  showAddScheduledJobCompletePostTasqPopupSummary = ''

  showAddScheduledJobCompletePostTasqPopupAssignee = ''

  showAddScheduledJobCompletePostTasqPopupDeadline = ''

  showAddScheduledJobChoosePreTasqBackgroundPopup = false

  showAddScheduledJobChoosePreTasqTemplatePopup = false

  showAddScheduledJobCompletePreTasqPopup = false

  showAddScheduledJobChoosePostTasqBackgroundPopup = false

  showAddScheduledJobChoosePostTasqTemplatePopup = false

  showAddScheduledJobCompletePostTasqPopup = false

  scheduleNewJobShowAddPreTasqChecklist = false

  scheduleNewJobShowAddPostTasqChecklist = false

  addJobSelectedPreTasqsArray: any[] = []

  addJobSelectedPostTasqsArray: any[] = []

  // Update existing job
  editJobPopupShowing = false;

  editJobName = ''

  editJobEnterJobSummary = ''

  editJobStartDate = ''

  editJobEndDate = ''

  editJobOperator = ''

  editJobExecutedBy = ''

  editJobSelectProcedure = ''

  editJobRigID = ''

  editJobDate: any[] = []

  editJobShowPreTasqMainContent = false

  editJobShowPostTasqMainContent = false

  editJobShowScheduleJobContent = false

  editJobShowScheduleJobPrePostTasqListContent = false

  showEditJobPreTasqsTemplateSelection = false

  showEditJobPostTasqsTemplateSelection = false

  editJobShowAddNewPretasqContent = false

  editJobShowAddNewPosttasqContent = false

  showEditScheduledJobCompletePreTasqPopupTitle = ''

  showEditScheduledJobCompletePreTasqPopupSummary = ''

  showEditScheduledJobCompletePreTasqPopupAssignee = ''

  showEditScheduledJobCompletePreTasqPopupDeadline = ''

  showEditScheduledJobCompletePostTasqPopupTitle = ''

  showEditScheduledJobCompletePostTasqPopupSummary = ''

  showEditScheduledJobCompletePostTasqPopupAssignee = ''

  showEditScheduledJobCompletePostTasqPopupDeadline = ''

  showEditScheduledJobChoosePreTasqBackgroundPopup = false

  showEditScheduledJobChoosePreTasqTemplatePopup = false

  showEditScheduledJobCompletePreTasqPopup = false

  showEditScheduledJobChoosePostTasqBackgroundPopup = false

  showEditScheduledJobChoosePostTasqTemplatePopup = false

  showEditScheduledJobCompletePostTasqPopup = false

  scheduleEditJobShowAddPreTasqChecklist = false

  scheduleEditJobShowAddPostTasqChecklist = false

  editJobSelectedPreTasqsArray: any[] = []

  editJobSelectedPostTasqsArray: any[] = []

  editScheduledJobShowDetailsContent = true

  editScheduledJobShowPreTasqContent = false

  editScheduledJobShowPostTasqContent = false

  dates: any[] = []

  currentDate = ''

  addNewRigPopupShowing = false;

  newRigName = ''

  newRigID = ''

  newPreTasqTitle = ''

  newPreTasqSummary = ''

  newPostTasqTitle = ''

  newPostTasqSummary = ''

  jobDetailsPopupShowing = false

  stepOneMainContentVisible = false

  showWordIndex = null

  showingRouteEditMenu = null

  showingPrePostTasqEditMenu = null

  newJobStartDateVal = ''

  newJobEndDateVal = ''

  // Filter options
  filterByRigOption = ''

  filterByWorkTypeOption = ''

  // Maintain the total list of options to select from rig filter dropdown
  rigsFilterArray: any[] = []

  loadingGanttChart = false

  viewTotalDays = 30

  routes: any[] = []

  newScheduledJobRigOptionsArray: any[] = []

  deleteRigRowConfirmShowing = null

  deletePrePostTasqConfirmShowing = null


  wouldBeNice() {
	  console.log("Check check")
  }

	mounted(){
	// @ts-ignore
	window.schedule = this;
	}

  clostConfirmDeleteRigPopup() {
	  this.deleteRigRowConfirmShowing = null;
  }

   closeConfirmDeletePrePostTasqPopup() {
	  this.deletePrePostTasqConfirmShowing = null;
  }

  deleteRigRow(route) {
    this.deleteRigRowConfirmShowing = route;
  }

  async confirmDeleteRigRow() {
	  this.dataLoading = true;
    const route = this.deleteRigRowConfirmShowing!;

    // @ts-ignore
    this.isDeletingRigLoadingView = route.RouteID;
    await scheduleModule.deleteRig({
      // @ts-ignore

      rig_id: route.RouteID,
    });
    this.isDeletingRigLoadingView = false;
    location.reload();
  }

   deletePrePostTasq(tasq) {
    this.deletePrePostTasqConfirmShowing = tasq;
  }


  async confirmDeletePrePostTasq() {
    this.dataLoading = true;
    const tasq = this.deletePrePostTasqConfirmShowing 
    await scheduleModule.deletePrePostTasq({
      // @ts-ignore
      id: tasq.id,
    });
    this.reloadPage();
    sleep(1000).then(() => {
        this.deletePrePostTasqConfirmShowing = null;
        this.dataLoading = false;
    });
  }

  toggleShowingRouteEditMenu(route_id) {
    if (this.showingRouteEditMenu == route_id) {
      this.showingRouteEditMenu = null;
    } else {
      this.showingRouteEditMenu = route_id;
    }
  }

  toggleShowingPrePostTasqEditMenu(tasqID) {
    if (this.showingPrePostTasqEditMenu === tasqID) {
      this.showingPrePostTasqEditMenu = null;
    } else {
      this.showingPrePostTasqEditMenu = tasqID;
    }
  }

  async toggleTaskChecklistItem(task, tasqType) {
    this.isSavingTasqCompletion = task.id;

    await scheduleModule.completePrePostTasqJob({
      tasq_id: task.id,
      tasq_type: tasqType,
      // @ts-ignore
      prediction_id: selectedJobGlobal!.PredictionID,
    });
    this.reloadPage();
    this.resetPageValues();
  }

  async togglePostTaskChecklistItem(postTask) {
    this.isSavingTasqCompletion = true;

    await scheduleModule.completePrePostTasqJob({
      tasq_id: postTask.id,
      tasq_type: 'Post',
      // @ts-ignore
      prediction_id: selectedJobGlobal!.PredictionID,
    });

    this.reloadPage();
    this.resetPageValues();
  }

  clostJobDetailsPopup() {
    this.resetPageValues();
  }

  async created() {
    this.reloadPage();
  }

  async reloadPage() {
    this.dates = [];
    this.routes = [];
    this.loadingGanttChart = true;
    await proceduresModule.getProcedures();
    this.currentDate = `${this.pad((new Date().getMonth() + 1), 2, '0')}/${this.pad((new Date().getDate()), 2, '0')}/${new Date().getFullYear()}`;
    let start_datetime = new Date(new Date().setHours(new Date().getHours() - 24));
    start_datetime = new Date(start_datetime.setHours(0));
    start_datetime = new Date(start_datetime.setMinutes(0));
    start_datetime = new Date(start_datetime.setSeconds(0));

    let end_datetime = new Date(new Date().setHours(start_datetime.getHours() + (this.viewTotalDays * 24)));
    end_datetime = new Date(end_datetime.setHours(0));
    end_datetime = new Date(end_datetime.setMinutes(0));
    end_datetime = new Date(end_datetime.setSeconds(0));

    // Show chart 30 days
    for (let days = 0; days < this.viewTotalDays; days++) {
      let day_datetime = new Date(start_datetime);
      day_datetime = new Date(day_datetime.setDate(start_datetime.getDate() + days));
      const hours_array: Date[] = [];
      const day_object = {
        day_id: day_datetime.toString(),
        day_short: day_datetime.getDate(),
        day: day_datetime,
        hours: hours_array,
      };
      for (let hours = 0; hours < 24; hours++) {
        let hour_datetime = new Date(day_datetime);
        hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours));
        day_object.hours.push(hour_datetime);
      }
      this.dates.push(day_object);
    }

    const startDateString = `${start_datetime.getUTCFullYear()}-${this.pad(start_datetime.getUTCMonth() + 1, 2, '0')}-${this.pad(start_datetime.getUTCDate(), 2, '0')}T${this.pad(start_datetime.getUTCHours(), 2, '0')}:${this.pad(start_datetime.getUTCMinutes(), 2, '0')}:${this.pad(start_datetime.getUTCSeconds(), 2, '0')}`;
    const endDateString = `${end_datetime.getUTCFullYear()}-${this.pad(end_datetime.getUTCMonth() + 1, 2, '0')}-${this.pad(end_datetime.getUTCDate(), 2, '0')}T${this.pad(end_datetime.getUTCHours(), 2, '0')}:${this.pad(end_datetime.getUTCMinutes(), 2, '0')}:${this.pad(end_datetime.getUTCSeconds(), 2, '0')}`;

    scheduleModule.resetScheduleJobs();
    scheduleModule.resetScheduleComponents();
    scheduleModule.resetScheduledRigComponents();

    await accountModule.getReassignmentList();
    await scheduleModule.getScheduledJobs({ start_date: startDateString, end_date: endDateString });
    setScheduledJobs(scheduleModule.scheduleJobs);
    this.newScheduledJobRigOptionsArray = [];
    this.rigsFilterArray = [];
    const filterScheduledRigComponents: any[] = [];
    for (var x = 0; x < this.scheduledRigComponents.length; x++) {
      this.rigsFilterArray.push({
        RigName: this.scheduledRigComponents[x].title_row.selection_name,
        RigID: this.scheduledRigComponents[x].title_row.selection_id,
        RigElementID: this.scheduledRigComponents[x].title_row.selection_id.toLowerCase().replaceAll(' ', ''),
      });
      if (this.filterByRigOption != '' && this.filterByRigOption != this.scheduledRigComponents[x].title_row.selection_name) {
        continue;
      }
      FILTER_BY_USERNAME_OPTION = this.filterByUsernameOption;

      filterScheduledRigComponents.push(this.scheduledRigComponents[x]);
      this.newScheduledJobRigOptionsArray.push({
        value: this.scheduledRigComponents[x].title_row.selection_id, text: this.scheduledRigComponents[x].title_row.selection_name,
      });
      this.routes.push({
        RouteName: this.scheduledRigComponents[x].title_row.selection_name,
        RouteID: this.scheduledRigComponents[x].title_row.selection_id,
        RouteElementID: this.scheduledRigComponents[x].title_row.selection_id.toLowerCase().replaceAll(' ', ''),
        DateTimes: [],
      });
    }
    for (var x = 0; x < this.routes.length; x++) {
      this.routes[x].DateTimes = this.dates;
    }
    ROUTES = this.routes;
    this.loadingGanttChart = false;
    sleep(500).then(() => {
      setupTodayDateMarker(this.dates);
      loadScheduleComponentsOnGanttChart(this.routes, filterScheduledRigComponents, start_datetime, end_datetime);
    });
    scheduleModule.resetPrePostTasqTemplate();
    await scheduleModule.getPrePostTasqTemplates();
  }

  get getUserReassignmentList(): any[] {
	  const results: any[] = [];
	  for (let x = 0; x < accountModule.reassignmentList.length; x++) {
		  if (accountModule.reassignmentList[x].first_name != null && accountModule.reassignmentList[x].last_name) {
			  results.push(accountModule.reassignmentList[x]);
		  }
	  }
	  return results;
  }

  get getPreTaskForJob(): any {
	  // @ts-ignore
	  if (selectedJobGlobal!.PreTasqChecklist.length == 0) {
		  return [];
	  }

	  // @ts-ignore
	  if (Array.isArray(selectedJobGlobal!.PreTasqChecklist[0])) {
		  return [];
	  }
	  // @ts-ignore
	  return selectedJobGlobal!.PreTasqChecklist;
  }

  get getPostTaskForJob(): any[] {
	  // @ts-ignore
	  if (selectedJobGlobal!.PostTasqChecklist.length == 0) {
		  return [];
	  }

	  // @ts-ignore
	  if (Array.isArray(selectedJobGlobal!.PostTasqChecklist[0])) {
		  return [];
	  }
	  // @ts-ignore
	  return selectedJobGlobal!.PostTasqChecklist;
  }

  get getProceduresDropdownList(): any[] {
	  const results: any[] = [];
	  for (let x = 0; x < proceduresModule.procedures.length; x++) {
		  // @ts-ignore
		  if (proceduresModule.procedures[x].ProcedureType == 'WellSpecific') {
			  // @ts-ignore
		  	  results.push(`${proceduresModule.procedures[x].ProcedureName} - ${proceduresModule.procedures[x].ProcedureID}`);
		  }
	  }
	  return results;
  }

  get usernameSelectionOptions(): any[] {
	  const usernameOptions: any[] = [];
	  for (let x = 0; x < accountModule.reassignmentList.length; x++) {
		  if (accountModule.reassignmentList[x].first_name != null && accountModule.reassignmentList[x].last_name != null) {
			  usernameOptions.push(`${accountModule.reassignmentList[x].first_name!} ${accountModule.reassignmentList[x].last_name!}`);
		  }
	  }
	  return usernameOptions;
  }

  getEmailFromUsernameSelected(first_name, last_name): string {
	  for (let x = 0; x < accountModule.reassignmentList.length; x++) {
		  if (accountModule.reassignmentList[x].first_name != null && accountModule.reassignmentList[x].last_name != null) {
			  if (accountModule.reassignmentList[x].first_name! == first_name && accountModule.reassignmentList[x].last_name! == last_name) {
				  return accountModule.reassignmentList[x].email;
			  }
		  }
	  }
	  return '';
  }

  get rigsData(): any[] {
	  const results: any[] = [];
	  for (let x = 0; x < this.rigsFilterArray.length; x++) {
		  results.push(`${this.rigsFilterArray[x].RigName} - ${this.rigsFilterArray[x].RigID}`);
	  }
    return results;
  }

  get currentDay(): number {
    return new Date().getDate();
  }

  get preTasqTemplateList(): any[] {
    return scheduleModule.preTasqTemplateList;
  }

  get postTasqTemplateList(): any[] {
    return scheduleModule.postTasqTemplateList;
  }

  get scheduledJobs(): any[] {
    return scheduleModule.scheduleJobs;
  }

  get scheduledRigComponents(): any[] {
    return scheduleModule.scheduledRigComponents;
  }

  get getCurrentName(): string {
    return getNameByEmail(accountModule.user.email);
  }

  resetPageValues() {
    this.isSavingTasqCompletion = false;

    this.editScheduledJobShowDetailsContent = true;
    this.editScheduledJobShowPreTasqContent = false;
    this.editScheduledJobShowPostTasqContent = false;

    this.newPreTasqTitle = '';
    this.newPreTasqSummary = '';
    this.newPostTasqTitle = '';
    this.newPostTasqSummary = '';

    this.newJobName = '';
    this.newJobEnterJobSummary = '';
    this.newJobStartDate = '';
    this.newJobEndDate = '';
    this.newJobOperator = '';
    this.newJobExecutedBy = '';
    this.newJobSelectProcedure = '';

    this.newJobStartDateVal = '';
    this.newJobEndDateVal = '';

    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = false;

    this.addJobShowScheduleJobPrePostTasqListContent = true;

    this.jobDetailsPopupShowing = false;
    this.stepOneMainContentVisible = false;

    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = false;

    this.scheduleNewJobShowAddPreTasqChecklist = false;
    this.scheduleNewJobShowAddPostTasqChecklist = false;

    this.showAddNewJobPreTasqsTemplateSelection = false;
    this.showAddNewJobPostTasqsTemplateSelection = false;

    this.showAddScheduledJobChoosePreTasqBackgroundPopup = false;
    this.showAddScheduledJobChoosePreTasqTemplatePopup = false;
    this.showAddScheduledJobCompletePreTasqPopup = false;

    this.showAddScheduledJobChoosePostTasqBackgroundPopup = false;
    this.showAddScheduledJobChoosePostTasqTemplatePopup = false;
    this.showAddScheduledJobCompletePostTasqPopup = false;

    this.showAddScheduledJobCompletePreTasqPopupTitle = '';
    this.showAddScheduledJobCompletePreTasqPopupSummary = '';
    this.showAddScheduledJobCompletePreTasqPopupAssignee = '';
    this.showAddScheduledJobCompletePreTasqPopupDeadline = '';

    this.showAddScheduledJobCompletePostTasqPopupTitle = '';
    this.showAddScheduledJobCompletePostTasqPopupSummary = '';
    this.showAddScheduledJobCompletePostTasqPopupAssignee = '';
    this.showAddScheduledJobCompletePostTasqPopupDeadline = '';

    this.addJobSelectedPreTasqsArray = [];
    this.addJobSelectedPostTasqsArray = [];

    this.addNewJobPopupShowing = false;
    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = false;
    this.jobDetailsPopupShowing = false;
    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = false;

    // Reset edit values

    // Update existing job
    this.editJobPopupShowing = false;
    this.editJobName = '';
    this.editJobEnterJobSummary = '';
    this.editJobStartDate = '';
    this.editJobEndDate = '';
    this.editJobOperator = '';
    this.editJobExecutedBy = '';
    this.editJobSelectProcedure = '';
    this.editJobRigID = '';
    this.editJobDate = [];
    this.editJobShowPreTasqMainContent = false;
    this.editJobShowPostTasqMainContent = false;
    this.editJobShowScheduleJobContent = false;
    this.editJobShowScheduleJobPrePostTasqListContent = false;
    this.showEditJobPreTasqsTemplateSelection = false;
    this.showEditJobPostTasqsTemplateSelection = false;
    this.editJobShowAddNewPretasqContent = false;
    this.editJobShowAddNewPosttasqContent = false;
    this.showEditScheduledJobCompletePreTasqPopupTitle = '';
    this.showEditScheduledJobCompletePreTasqPopupSummary = '';
    this.showEditScheduledJobCompletePreTasqPopupAssignee = '';
    this.showEditScheduledJobCompletePreTasqPopupDeadline = '';
    this.showEditScheduledJobCompletePostTasqPopupTitle = '';
    this.showEditScheduledJobCompletePostTasqPopupSummary = '';
    this.showEditScheduledJobCompletePostTasqPopupAssignee = '';
    this.showEditScheduledJobCompletePostTasqPopupDeadline = '';
    this.showEditScheduledJobChoosePreTasqBackgroundPopup = false;
    this.showEditScheduledJobChoosePreTasqTemplatePopup = false;
    this.showEditScheduledJobCompletePreTasqPopup = false;
    this.showEditScheduledJobChoosePostTasqBackgroundPopup = false;
    this.showEditScheduledJobChoosePostTasqTemplatePopup = false;
    this.showEditScheduledJobCompletePostTasqPopup = false;
    this.scheduleEditJobShowAddPreTasqChecklist = false;
    this.scheduleEditJobShowAddPostTasqChecklist = false;
    this.editJobSelectedPreTasqsArray = [];
    this.editJobSelectedPostTasqsArray = [];

    // @ts-ignore
    this.$refs.jobDetailsPopupID.style.display = 'none';
  }

  userFilterOptionSelected(username) {
    this.filterByUsernameOption = username.toLowerCase();
    this.reloadPage();
  }

  rigOptionSelected(rigName) {
    this.filterByRigOption = rigName;
    this.reloadPage();
  }

  removePostTasqChecklistItem(index) {
    this.addJobSelectedPostTasqsArray.splice(index, 1);
  }

  removePreTasqChecklistItem(index) {
    this.addJobSelectedPreTasqsArray.splice(index, 1);
  }

  removeEditPreTasqChecklistItem(index) {
    this.editJobSelectedPreTasqsArray.splice(index, 1);
  }

  removeEditPostTasqChecklistItem(index) {
    this.editJobSelectedPostTasqsArray.splice(index, 1);
  }

  hideAddScheduledJobCompletePreTasqPopupClose() {
    this.showAddScheduledJobChoosePreTasqBackgroundPopup = false;
    this.showAddScheduledJobCompletePreTasqPopup = false;
    this.showAddScheduledJobChoosePreTasqTemplatePopup = false;
  }

  hideEditScheduledJobCompletePreTasqPopupClose() {
    this.showEditScheduledJobChoosePreTasqBackgroundPopup = false;
    this.showEditScheduledJobCompletePreTasqPopup = false;
    this.showEditScheduledJobChoosePreTasqTemplatePopup = false;
  }

  hideAddScheduledJobCompletePostTasqPopupClose() {
    this.showAddScheduledJobChoosePostTasqBackgroundPopup = false;
    this.showAddScheduledJobCompletePostTasqPopup = false;
    this.showAddScheduledJobChoosePostTasqTemplatePopup = false;
  }

  hideEditScheduledJobCompletePostTasqPopupClose() {
    this.showEditScheduledJobChoosePostTasqBackgroundPopup = false;
    this.showEditScheduledJobCompletePostTasqPopup = false;
    this.showEditScheduledJobChoosePostTasqTemplatePopup = false;
  }

  addNewPostTasqJob() {
    this.addJobSelectedPostTasqsArray.push(
      {
        id: uuidv4(),
        type: 'custom',
        tasqTitle: this.showAddScheduledJobCompletePostTasqPopupTitle,
        tasqSummary: this.showAddScheduledJobCompletePostTasqPopupSummary,
        assignee: this.showAddScheduledJobCompletePostTasqPopupAssignee,
        deadline: this.showAddScheduledJobCompletePostTasqPopupDeadline,
        complete: false,
      },
    );
    this.showAddScheduledJobCompletePostTasqPopupTitle = '';
    this.showAddScheduledJobCompletePostTasqPopupSummary = '';
    this.showAddScheduledJobCompletePostTasqPopupAssignee = '';
    this.showAddScheduledJobCompletePostTasqPopupDeadline = '';
    this.showAddScheduledJobCompletePostTasqPopup = false;
    this.showAddScheduledJobChoosePostTasqBackgroundPopup = false;
    this.showAddScheduledJobChoosePostTasqTemplatePopup = false;
  }

  addNewEditPostTasqJob() {
    this.editJobSelectedPostTasqsArray.push(
      {
        id: uuidv4(),
        type: 'custom',
        tasqTitle: this.showEditScheduledJobCompletePostTasqPopupTitle,
        tasqSummary: this.showEditScheduledJobCompletePostTasqPopupSummary,
        assignee: this.showEditScheduledJobCompletePostTasqPopupAssignee,
        deadline: this.showEditScheduledJobCompletePostTasqPopupDeadline,
        complete: false,
      },
    );
    this.showEditScheduledJobCompletePostTasqPopupTitle = '';
    this.showEditScheduledJobCompletePostTasqPopupSummary = '';
    this.showEditScheduledJobCompletePostTasqPopupAssignee = '';
    this.showEditScheduledJobCompletePostTasqPopupDeadline = '';
    this.showEditScheduledJobCompletePostTasqPopup = false;
    this.showEditScheduledJobChoosePostTasqBackgroundPopup = false;
    this.showEditScheduledJobChoosePostTasqTemplatePopup = false;
  }

  addNewPreTasqJob() {
    this.addJobSelectedPreTasqsArray.push(
      {
        id: uuidv4(),
        type: 'custom',
        tasqTitle: this.showAddScheduledJobCompletePreTasqPopupTitle,
        tasqSummary: this.showAddScheduledJobCompletePreTasqPopupSummary,
        assignee: this.showAddScheduledJobCompletePreTasqPopupAssignee,
        deadline: this.showAddScheduledJobCompletePreTasqPopupDeadline,
        complete: false,
      },
    );
    this.showAddScheduledJobCompletePreTasqPopupTitle = '';
    this.showAddScheduledJobCompletePreTasqPopupSummary = '';
    this.showAddScheduledJobCompletePreTasqPopupAssignee = '';
    this.showAddScheduledJobCompletePreTasqPopupDeadline = '';
    this.showAddScheduledJobCompletePreTasqPopup = false;
    this.showAddScheduledJobChoosePreTasqBackgroundPopup = false;
    this.showAddScheduledJobChoosePreTasqTemplatePopup = false;
  }

  addNewEditPreTasqJob() {
    this.editJobSelectedPreTasqsArray.push(
      {
        id: uuidv4(),
        type: 'custom',
        tasqTitle: this.showEditScheduledJobCompletePreTasqPopupTitle,
        tasqSummary: this.showEditScheduledJobCompletePreTasqPopupSummary,
        assignee: this.showEditScheduledJobCompletePreTasqPopupAssignee,
        deadline: this.showEditScheduledJobCompletePreTasqPopupDeadline,
        complete: false,
      },
    );
    this.showEditScheduledJobCompletePreTasqPopupTitle = '';
    this.showEditScheduledJobCompletePreTasqPopupSummary = '';
    this.showEditScheduledJobCompletePreTasqPopupAssignee = '';
    this.showEditScheduledJobCompletePreTasqPopupDeadline = '';
    this.showEditScheduledJobCompletePreTasqPopup = false;
    this.showEditScheduledJobChoosePreTasqBackgroundPopup = false;
    this.showEditScheduledJobChoosePreTasqTemplatePopup = false;
  }

  showSelectEditPostTasqChecklistTemplateSelectionPopup() {
    this.scheduleEditJobShowAddPostTasqChecklist = false;
    this.showEditScheduledJobChoosePostTasqBackgroundPopup = true;
    this.showEditScheduledJobChoosePostTasqTemplatePopup = true;
    this.showEditScheduledJobCompletePostTasqPopup = false;
  }

  showSelectPostTasqChecklistTemplateSelectionPopup() {
    this.scheduleNewJobShowAddPostTasqChecklist = false;
    this.showAddScheduledJobChoosePostTasqBackgroundPopup = true;
    this.showAddScheduledJobChoosePostTasqTemplatePopup = true;
    this.showAddScheduledJobCompletePostTasqPopup = false;
  }

  showSelectPostTasqChecklistNewPostTasqPopup() {
    this.scheduleNewJobShowAddPostTasqChecklist = false;
    this.showAddScheduledJobChoosePostTasqBackgroundPopup = true;
    this.showAddScheduledJobChoosePostTasqTemplatePopup = false;
    this.showAddScheduledJobCompletePostTasqPopup = true;
  }

  showSelectEditPostTasqChecklistNewPostTasqPopup() {
	  this.scheduleEditJobShowAddPostTasqChecklist = false;
    this.showEditScheduledJobChoosePostTasqBackgroundPopup = true;
    this.showEditScheduledJobChoosePostTasqTemplatePopup = false;
    this.showEditScheduledJobCompletePostTasqPopup = true;
  }

  showSelectPreTasqChecklistTemplateSelectionPopup() {
    this.scheduleNewJobShowAddPreTasqChecklist = false;
    this.showAddScheduledJobChoosePreTasqBackgroundPopup = true;
    this.showAddScheduledJobChoosePreTasqTemplatePopup = true;
    this.showAddScheduledJobCompletePreTasqPopup = false;
  }

  showSelectPreTasqChecklistNewPreTasqPopup() {
    this.scheduleNewJobShowAddPreTasqChecklist = false;
    this.showAddScheduledJobChoosePreTasqBackgroundPopup = true;
    this.showAddScheduledJobChoosePreTasqTemplatePopup = false;
    this.showAddScheduledJobCompletePreTasqPopup = true;
  }

  showSelectEditPreTasqChecklistNewPreTasqPopup() {
    this.scheduleEditJobShowAddPreTasqChecklist = false;
    this.showEditScheduledJobChoosePreTasqBackgroundPopup = true;
    this.showEditScheduledJobChoosePreTasqTemplatePopup = false;
    this.showEditScheduledJobCompletePreTasqPopup = true;
  }

  showSelectEditPreTasqChecklistTemplateSelectionPopup() {
    this.scheduleEditJobShowAddPreTasqChecklist = false;
    this.showEditScheduledJobChoosePreTasqBackgroundPopup = true;
    this.showEditScheduledJobChoosePreTasqTemplatePopup = true;
    this.showEditScheduledJobCompletePreTasqPopup = false;
  }

  postTasqTemplateOptionSelected(template) {
    this.showAddScheduledJobCompletePostTasqPopupTitle = template.tasqTitle;
    this.showAddScheduledJobCompletePostTasqPopupSummary = template.tasqSummary;
    this.showAddScheduledJobCompletePostTasqPopupAssignee = '';
    this.showAddScheduledJobCompletePostTasqPopupDeadline = '';
    this.showAddScheduledJobChoosePostTasqBackgroundPopup = true;
    this.showAddScheduledJobChoosePostTasqTemplatePopup = false;
    this.showAddScheduledJobCompletePostTasqPopup = true;
  }

  preTasqTemplateOptionSelected(template) {
    this.showAddScheduledJobCompletePreTasqPopupTitle = template.tasqTitle;
    this.showAddScheduledJobCompletePreTasqPopupSummary = template.tasqSummary;
    this.showAddScheduledJobCompletePreTasqPopupAssignee = '';
    this.showAddScheduledJobCompletePreTasqPopupDeadline = '';
    this.showAddScheduledJobChoosePreTasqBackgroundPopup = true;
    this.showAddScheduledJobChoosePreTasqTemplatePopup = false;
    this.showAddScheduledJobCompletePreTasqPopup = true;
  }

  postEditTasqTemplateOptionSelected(template) {
    this.showEditScheduledJobCompletePostTasqPopupTitle = template.tasqTitle;
    this.showEditScheduledJobCompletePostTasqPopupSummary = template.tasqSummary;
    this.showEditScheduledJobCompletePostTasqPopupAssignee = '';
    this.showEditScheduledJobCompletePostTasqPopupDeadline = '';
    this.showEditScheduledJobChoosePostTasqBackgroundPopup = true;
    this.showEditScheduledJobChoosePostTasqTemplatePopup = false;
    this.showEditScheduledJobCompletePostTasqPopup = true;
  }

  preEditTasqTemplateOptionSelected(template) {
    this.showEditScheduledJobCompletePreTasqPopupTitle = template.tasqTitle;
    this.showEditScheduledJobCompletePreTasqPopupSummary = template.tasqSummary;
    this.showEditScheduledJobCompletePreTasqPopupAssignee = '';
    this.showEditScheduledJobCompletePreTasqPopupDeadline = '';
    this.showEditScheduledJobChoosePreTasqBackgroundPopup = true;
    this.showEditScheduledJobChoosePreTasqTemplatePopup = false;
    this.showEditScheduledJobCompletePreTasqPopup = true;
  }

  addNewPosttaskButtonTapped() {
    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = true;
    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = false;
    this.addJobShowScheduleJobPrePostTasqListContent = false;
  }

  addNewPretaskButtonTapped() {
    this.addJobShowAddNewPretasqContent = true;
    this.addJobShowAddNewPosttasqContent = false;
    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = false;
    this.addJobShowScheduleJobPrePostTasqListContent = false;
  }

  showAddJobPreTasqMainContent() {
    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = false;
    this.addJobShowPreTasqMainContent = true;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = false;
    this.addJobShowScheduleJobPrePostTasqListContent = false;
  }

  showAddJobPostTasqMainContent() {
    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = false;
    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = true;
    this.addJobShowScheduleJobContent = false;
    this.addJobShowScheduleJobPrePostTasqListContent = false;
  }

  showAddJobContent() {
    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = false;
    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = true;
    this.addJobShowScheduleJobPrePostTasqListContent = false;
  }

  showEditJobContent() {
    this.editJobShowAddNewPretasqContent = false;
    this.editJobShowAddNewPosttasqContent = false;
    this.editJobShowPreTasqMainContent = false;
    this.editJobShowPostTasqMainContent = false;
    this.editJobShowScheduleJobContent = true;
    this.editJobShowScheduleJobPrePostTasqListContent = false;
  }

  editJobDetailsPopup() {
    this.jobDetailsPopupShowing = false;
	  // @ts-ignore
	  this.$refs.jobDetailsPopupID.style.display = 'none';
    this.showEditJobPopup();
  }

  deleteJobDetailsPopup() {
    deleteJob();
  }

  hideJobDetailsPopup() {
    this.jobDetailsPopupShowing = false;
  }

  get procedures() {
    return proceduresModule.procedures;
  }

  async showJobDetailsPopup() {
    this.jobDetailsPopupShowing = true;
  }

  didSelectComponent() {
    this.showJobDetailsPopup();
  }

  hideAddNewRigPopup() {
    this.addNewRigPopupShowing = false;
  }

  addNewRigClicked() {
    this.addNewRigPopupShowing = true;
  }

  loadJobDetailsInEditVue() {
	  loadJobDetailsInEdit();
  }

  async submitNewRig() {
    this.dataLoading = true;
    const payload = {
      RigName: this.newRigName,
      RigID: this.newRigID,
    };

    await scheduleModule.postNewRig(payload);
    this.dataLoading = false;
    this.hideAddNewRigPopup();
    this.reloadPage();

    // newRigID
    // newRigname
  }

  async postNewPreTasqJob() {
    this.dataLoading = true;
    const payload = {
      TasqType: 'PreTasq',
      TasqTitle: this.newPreTasqTitle,
      TasqSummary: this.newPreTasqSummary,
    };

    await scheduleModule.postNewPrePostTasqTemplate(payload);
    this.dataLoading = false;
    this.showAddJobPreTasqMainContent();
    scheduleModule.resetPrePostTasqTemplate();
    await scheduleModule.getPrePostTasqTemplates();
  }

  async postNewPostTasqJob() {
    this.dataLoading = true;
    const payload = {
      TasqType: 'PostTasq',
      TasqTitle: this.newPostTasqTitle,
      TasqSummary: this.newPostTasqSummary,
    };

    await scheduleModule.postNewPrePostTasqTemplate(payload);
    this.dataLoading = false;
    this.showAddJobPostTasqMainContent();
    scheduleModule.resetPrePostTasqTemplate();
    await scheduleModule.getPrePostTasqTemplates();
  }

  postNewScheduledJobPrePostTasqListsBackTapped() {
    this.showAddJobContent();
  }

  updateNewScheduledJobPrePostTasqListsBackTapped() {
    this.showEditJobContent();
  }

  editScheduledJobPrePostTasqLists() {
    this.editJobShowPreTasqMainContent = false;
    this.editJobShowPostTasqMainContent = false;
    this.editJobShowScheduleJobContent = false;
    this.editJobShowScheduleJobPrePostTasqListContent = true;
  }

  postNewScheduledJobPrePostTasqLists() {
    this.addJobShowAddNewPretasqContent = false;
    this.addJobShowAddNewPosttasqContent = false;
    this.addJobShowPreTasqMainContent = false;
    this.addJobShowPostTasqMainContent = false;
    this.addJobShowScheduleJobContent = false;
    this.addJobShowScheduleJobPrePostTasqListContent = true;
  }

  async postNewScheduledJob() {
    let newJobRigName;

    for (var x = 0; x < this.newScheduledJobRigOptionsArray.length; x++) {
      if (this.newJobRigID.split(' - ')[1] == this.newScheduledJobRigOptionsArray[x].value) {
        newJobRigName = this.newScheduledJobRigOptionsArray[x].text;
      }
    }

    let newJobStartDateFormatted = this.newJobDate[1];
    let newJobEndDateFormatted = this.newJobDate[0];
    if (typeof this.newJobDate[0] !== 'string') {
      newJobStartDateFormatted = `${this.newJobDate[1].getFullYear()}-${this.pad((this.newJobDate[1].getMonth() + 1), 2, '0')}-${this.pad((this.newJobDate[1].getDate()), 2, '0')}`;
      newJobEndDateFormatted = `${this.newJobDate[0].getFullYear()}-${this.pad((this.newJobDate[0].getMonth() + 1), 2, '0')}-${this.pad((this.newJobDate[0].getDate()), 2, '0')}`;
    }

    this.newJobStartDate = `${newJobStartDateFormatted}T00:00:00`;
    this.newJobEndDate = `${newJobEndDateFormatted}T00:00:00`;

    const selected_procedure_id = this.newJobSelectProcedure.split(' - ')[1];
    let selected_procedure;
    for (var x = 0; x < proceduresModule.procedures.length; x++) {
      // @ts-ignore
      if (selected_procedure_id == proceduresModule.procedures[x].ProcedureID) {
        selected_procedure = proceduresModule.procedures[x];
        break;
      }
    }

    const addJobSelectedPreTasqsArrayLocal: any[] = [];
    for (var t = 0; t < this.addJobSelectedPreTasqsArray.length; t++) {
      addJobSelectedPreTasqsArrayLocal.push(JSON.stringify(this.addJobSelectedPreTasqsArray[t]));
    }
    const addJobSelectedPostTasqsArrayLocal: any[] = [];
    for (var t = 0; t < this.addJobSelectedPostTasqsArray.length; t++) {
      addJobSelectedPostTasqsArrayLocal.push(JSON.stringify(this.addJobSelectedPostTasqsArray[t]));
    }

    this.dataLoading = true;
    const payload = {
      job_name: this.newJobName,
      job_operator: this.getEmailFromUsernameSelected(this.newJobOperator.split(' ')[0], this.newJobOperator.split(' ')[1]).toLowerCase(),
      job_summary: this.newJobEnterJobSummary,
      job_type: 'Workover',
      node_id: selected_procedure.eventWellName,
      procedure_id: selected_procedure_id,
      role: 'Engineer',
      rig_id: this.newJobRigID.split(' - ')[1],
      rig_name: newJobRigName,
      selected_end_date: this.newJobStartDate,
      selected_start_date: this.newJobEndDate,
      username: this.getEmailFromUsernameSelected(this.newJobExecutedBy.split(' ')[0], this.newJobExecutedBy.split(' ')[1]).toLowerCase(),
      pre_tasq_checklist: addJobSelectedPreTasqsArrayLocal,
      post_tasq_checklist: addJobSelectedPostTasqsArrayLocal,
    };

    await scheduleModule.postNewScheduledJob(payload);
    this.dataLoading = false;
    this.hideAddNewJobPopup();
    this.reloadPage();
  }

  async updateScheduledJob() {
    let editJobRigName;
    for (var x = 0; x < this.newScheduledJobRigOptionsArray.length; x++) {
      if (this.editJobRigID == this.newScheduledJobRigOptionsArray[x].value) {
        editJobRigName = this.newScheduledJobRigOptionsArray[x].text;
      }
    }

    let editJobStartDateFormatted = this.editJobDate[1];
    let editJobEndDateFormatted = this.editJobDate[0];
    if (typeof this.editJobDate[0] !== 'string') {
      editJobStartDateFormatted = `${this.editJobDate[1].getFullYear()}-${this.pad((this.editJobDate[1].getMonth() + 1), 2, '0')}-${this.pad((this.editJobDate[1].getDate()), 2, '0')}`;
      editJobEndDateFormatted = `${this.editJobDate[0].getFullYear()}-${this.pad((this.editJobDate[0].getMonth() + 1), 2, '0')}-${this.pad((this.editJobDate[0].getDate()), 2, '0')}`;
    }

    this.editJobStartDate = `${editJobStartDateFormatted}T00:00:00`;
    this.editJobEndDate = `${editJobEndDateFormatted}T00:00:00`;

    this.dataLoading = true;
    // herrrrrrrrrrrr

    const selected_procedure_id = this.editJobSelectProcedure.split(' - ')[1];
    let selected_procedure;
    for (var x = 0; x < proceduresModule.procedures.length; x++) {
      // @ts-ignore
      if (selected_procedure_id == proceduresModule.procedures[x].ProcedureID) {
        selected_procedure = proceduresModule.procedures[x];
        break;
      }
    }

    const editJobSelectedPreTasqsArrayLocal: any[] = [];
    for (var t = 0; t < this.editJobSelectedPreTasqsArray.length; t++) {
      editJobSelectedPreTasqsArrayLocal.push(JSON.stringify(this.editJobSelectedPreTasqsArray[t]));
    }
    const editJobSelectedPostTasqsArrayLocal: any[] = [];
    for (var t = 0; t < this.editJobSelectedPostTasqsArray.length; t++) {
      editJobSelectedPostTasqsArrayLocal.push(JSON.stringify(this.editJobSelectedPostTasqsArray[t]));
    }

    const payload = {
      // @ts-ignore
      workflow_task_id: selectedJobGlobal!.WorkflowTaskID,
      job_name: this.editJobName,
      job_operator: this.getEmailFromUsernameSelected(this.editJobOperator.split(' ')[0], this.editJobOperator.split(' ')[1]).toLowerCase(),
      job_summary: this.editJobEnterJobSummary,
      job_type: 'Workover',
      node_id: selected_procedure.eventWellName,
      procedure_id: selected_procedure_id,
      rig_id: this.editJobRigID.split(' - ')[1],
      rig_name: editJobRigName,
      selected_end_date: this.editJobStartDate,
      selected_start_date: this.editJobEndDate,
      username: this.getEmailFromUsernameSelected(this.editJobExecutedBy.split(' ')[0], this.editJobExecutedBy.split(' ')[1]).toLowerCase(),
      pre_tasq_checklist: editJobSelectedPreTasqsArrayLocal,
      post_tasq_checklist: editJobSelectedPostTasqsArrayLocal,
    };

    await scheduleModule.putScheduledJob(payload);
    this.dataLoading = false;
    // this.hideEditJobPopup()
    location.reload();
    // this.reloadPage()
  }

  hideAddNewJobPopup() {
    this.resetPageValues();
  }

  hideEditJobPopup() {
    this.resetPageValues();
  }

  showEditJobPopup() {
	  this.editJobShowScheduleJobContent = true;
    this.editJobPopupShowing = true;

	  // @ts-ignore
	  this.editJobName = selectedJobGlobal!.JobName;
	  // @ts-ignore
	  this.editJobEnterJobSummary = selectedJobGlobal!.JobSummary;
	  // @ts-ignore
	  this.editJobRigID = `${selectedJobGlobal!.RigName} - ${selectedJobGlobal!.RigID}`;

	  // @ts-ignore
	  this.editJobDate = [(new Date(selectedJobGlobal!.SelectedStartDate)), (new Date(selectedJobGlobal!.SelectedEndDate))];

	  let operator_name = '';
	  let executed_by_name = '';
	  for (let u = 0; u < accountModule.reassignmentList.length; u++) {
		  // @ts-ignore
		  if (accountModule.reassignmentList[u].email.toLowerCase() == selectedJobGlobal!.JobOperator.toLowerCase()) {
			  operator_name = `${accountModule.reassignmentList[u].first_name} ${accountModule.reassignmentList[u].last_name}`;
		  }
		  // @ts-ignore
		  if (accountModule.reassignmentList[u].email.toLowerCase() == selectedJobGlobal!.Username.toLowerCase()) {
			  executed_by_name = `${accountModule.reassignmentList[u].first_name} ${accountModule.reassignmentList[u].last_name}`;
		  }
	  }

	  this.editJobOperator = operator_name;
	  this.editJobExecutedBy = executed_by_name;

	  for (let pr = 0; pr < proceduresModule.procedures.length; pr++) {
		  // @ts-ignore
		  if (proceduresModule.procedures[pr].ProcedureID == selectedJobGlobal!.ProcedureID) {
			  // @ts-ignore
			  this.editJobSelectProcedure = `${proceduresModule.procedures[pr].ProcedureName} - ${proceduresModule.procedures[pr].ProcedureID}`;
			  break;
		  }
	  }

	  // @ts-ignore
	  for (var c = 0; c < selectedJobGlobal!.PreTasqChecklist.length; c++) {
		  this.editJobSelectedPreTasqsArray.push(
        {
          // @ts-ignore
          id: selectedJobGlobal!.PreTasqChecklist[c].id,
          // @ts-ignore
          type: selectedJobGlobal!.PreTasqChecklist[c].type,
          // @ts-ignore
          tasqTitle: selectedJobGlobal!.PreTasqChecklist[c].tasqTitle,
          // @ts-ignore
          tasqSummary: selectedJobGlobal!.PreTasqChecklist[c].tasqSummary,
          // @ts-ignore
          assignee: selectedJobGlobal!.PreTasqChecklist[c].assignee,
          // @ts-ignore
          deadline: selectedJobGlobal!.PreTasqChecklist[c].deadline,
          // @ts-ignore
          complete: selectedJobGlobal!.PreTasqChecklist[c].complete,
        },
		  );
	  }

	  // @ts-ignore
	  for (var c = 0; c < selectedJobGlobal!.PostTasqChecklist.length; c++) {
		  this.editJobSelectedPostTasqsArray.push(
        {
          // @ts-ignore
          id: selectedJobGlobal!.PostTasqChecklist[c].id,
          // @ts-ignore
          type: selectedJobGlobal!.PostTasqChecklist[c].type,
          // @ts-ignore
          tasqTitle: selectedJobGlobal!.PostTasqChecklist[c].tasqTitle,
          // @ts-ignore
          tasqSummary: selectedJobGlobal!.PostTasqChecklist[c].tasqSummary,
          // @ts-ignore
          assignee: selectedJobGlobal!.PostTasqChecklist[c].assignee,
          // @ts-ignore
          deadline: selectedJobGlobal!.PostTasqChecklist[c].deadline,
          // @ts-ignore
          complete: selectedJobGlobal!.PostTasqChecklist[c].complete,
        },
		  );
	  }
  }

  showAddNewJobPopup() {
    this.addJobShowScheduleJobPrePostTasqListContent = false;
    this.addJobShowScheduleJobContent = true;
    this.addNewJobPopupShowing = true;
    //    selectedJobGlobal
  }

  settingsData = {
    startDate: DateTime.local().toSQLDate(),
    endDate: DateTime.local().plus({ days: 30 }).toSQLDate(),
    weekdayText: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ],
    unit: 240,
    borderW: 1,
    dateDivH: 25,
    timeDivH: 25,
    unitDivW: 25,
    titleDivW: 20,
    rowH: 100,
  };

  pad(n, width, z) {
    z = z || '0';
    n = `${n}`;
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
}

const SCHEDULE_COMPONENT_COLORS = ['#51cea8', '#e9a284', '#516ae9', '#878891'];
const SCHEDULE_COMPONENT_USER_COLORS = {};
const DATE_TITLE_HEIGHT = 35;
const CHART_DAY_WIDTH = 110;
const IN_ROUTE_HEIGHT = 80;
let CHART_START_DATETIME;
var ROUTES: any[] = [];
let UPDATED_JOBS: any[] = [];
var FILTER_BY_USERNAME_OPTION = '';
const PIXELS_TO_CURRENT_DAY = 361;

function setupTodayDateMarker(datesArray) {
  const currentDate = new Date();
  for (let x = 0; x < datesArray.length; x++) {
    if (currentDate.getDate() == datesArray[x].day_short) {
      const markerDiv = document.createElement('div');
      markerDiv.style.width = '1px';
      markerDiv.style.height = `calc(100% - ${DATE_TITLE_HEIGHT - 4}px)`;
      markerDiv.style.left = `${(CHART_DAY_WIDTH * x) + (CHART_DAY_WIDTH / 2)}px`;
      markerDiv.style.top = `${DATE_TITLE_HEIGHT - 5}px`;
      markerDiv.style.backgroundColor = '1px';
      markerDiv.classList.add('schedule_marker_background');
      markerDiv.style.position = 'absolute';

            document.getElementById('ganttChartScrollableContentID')?.appendChild(markerDiv);
            return;
    }
  }
}

let SCHEDULED_JOBS: any[] = [];

function setScheduledJobs(jobs) {
  SCHEDULED_JOBS = jobs;
  UPDATED_JOBS = JSON.parse(JSON.stringify(SCHEDULED_JOBS));
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function parseDate(str) {
  const mdy = str.split('/');
  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

let routeRowIDs: string[] = [];

var selectedJobGlobal = null;

async function deleteJob() {
  if (selectedJobGlobal != null) {
		document.getElementById('jobDetailsLoadingViewID')!.style.display = 'block';
		await scheduleModule.deleteScheduledJob({
		  // @ts-ignore
		  workflow_task_id: selectedJobGlobal!.WorkflowTaskID,
		});
		document.getElementById('jobDetailsLoadingViewID')!.style.display = 'none';

		location.reload();
  }
}

// @ts-ignore
import scheduleTest from '@/views/Schedule';
// import scheduleTest from "Schedule.vue";

function loadScheduleComponentsOnGanttChart(routes, scheduleComponents, chart_start_datetime, chart_end_datetime) {
	// @ts-ignore
	// window.test = this;
	// @ts-ignore
	window.schedule.wouldBeNice()
	console.log(Vue)
	console.log(scheduleTest)

	CHART_START_DATETIME = chart_start_datetime;
  routeRowIDs = [];
  for (var x = 0; x < routes.length; x++) {
    // routeRowIDs.push(`route_row_${routes[x].RouteElementID}`);
  }

  for (const key in scheduleComponents) {
    const { rows } = scheduleComponents[key];

    const route_element_id = scheduleComponents[key].title_row.selection_id.replaceAll(' ', '').toLowerCase();

    const route_row_container_id = `route_component_row_${route_element_id}`;
    const route_row_id = `route_row_${route_element_id}`;
    const route_row_title_id = `route_row_title_${route_element_id}`;
    const element = document.getElementById(route_row_container_id);

    if (typeof (element) !== 'undefined' && element != null) {
            document.getElementById(route_row_container_id)!.innerHTML = '';
    } else {

    }

    const route_row_height = (IN_ROUTE_HEIGHT * (rows.length - 1)) + 150;

        document.getElementById(route_row_id)!.style.minHeight = `${route_row_height.toString()}px`;
        document.getElementById(route_row_title_id)!.style.minHeight = `${route_row_height.toString()}px`;

        // Loop through and add rows to a title_row row
        for (var x = 0; x < rows.length; x++) {
          const row = rows[x];

          let row_height = `${IN_ROUTE_HEIGHT.toString()}px`;
          if ((rows.length - 1) == x) {
            row_height = '150px';
          }
          const row_div = document.createElement('div');
          row_div.style.position = 'relative';
          row_div.style.height = row_height;
          row_div.style.float = 'left';
          row_div.style.clear = 'both';
		  
		  row_div.id = `${x.toString()}_test_this_${scheduleComponents[key].title_row.selection_id}`;
		  routeRowIDs.push(`${x.toString()}_test_this_${scheduleComponents[key].title_row.selection_id}`);
        //   row_div.style.backgroundColor = 'purple';

          for (let y = 0; y < row.components.length; y++) {
            const component = row.components[y];
            const days_diff = datediff(chart_start_datetime, new Date(component.start_date));
            const width_days_diff = datediff(new Date(component.start_date), new Date(component.end_date)) + 1;

            let color = '#51cea8';
            if (SCHEDULE_COMPONENT_USER_COLORS[component.assignee.selection_name] != null) {
              color = SCHEDULE_COMPONENT_USER_COLORS[component.assignee.selection_name];
            } else {
              color = SCHEDULE_COMPONENT_COLORS[Object.keys(SCHEDULE_COMPONENT_USER_COLORS).length % SCHEDULE_COMPONENT_COLORS.length];
              SCHEDULE_COMPONENT_USER_COLORS[component.assignee.selection_name] = color;
            }

            const component_div = document.createElement('div');
            let component_height = '50px';
            if (component.pre_tasq_checklist != null || component.post_tasq_checklist != null) {
              if (component.pre_tasq_checklist.length > 0 || component.post_tasq_checklist.length > 0) {
                if (!(Array.isArray(component.pre_tasq_checklist[0])) || !(Array.isArray(component.post_tasq_checklist[0]))) {
                  component_height = '70px';
                }
              }
            }
            component_div.style.boxShadow = '0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.15)';
            component_div.style.borderRadius = '8px';
            component_div.style.position = 'absolute';
            component_div.style.width = `${(width_days_diff * CHART_DAY_WIDTH).toString()}px`;
            component_div.style.height = component_height;
            component_div.style.background = color;
            component_div.style.left = `${(days_diff * CHART_DAY_WIDTH).toString()}px`;
            component_div.style.top = '10px';
            component_div.style.overflow = 'hidden';
            component_div.style.cursor = 'move';
            component_div.style.zIndex = '999999';
            component_div.setAttribute('componentID', component.component_id);
            component_div.setAttribute('isDraggableComponent', 'true');
            dragElement(component_div);

            const component_title_p = document.createElement('p');
            component_title_p.style.position = 'relative';
            component_title_p.style.color = 'white';
            component_title_p.style.marginTop = '4px';
            component_title_p.style.marginLeft = '10px';
            component_title_p.style.marginBottom = '0px';
            component_title_p.style.paddingBottom = '0px';
            component_title_p.style.fontSize = '15px';
            component_title_p.style.fontWeight = '900';
            component_title_p.style.width = 'calc(100% - 20px)';
            component_title_p.innerHTML = component.well;

            const component_sub_title_p = document.createElement('p');
            component_sub_title_p.style.position = 'relative';
            component_sub_title_p.style.color = 'white';
            component_sub_title_p.style.marginTop = '0px';
            component_sub_title_p.style.paddingTop = '0px';
            component_sub_title_p.style.marginLeft = '10px';
            component_sub_title_p.style.fontSize = '13px';
            component_sub_title_p.style.fontWeight = '300';
            component_title_p.style.width = 'calc(100% - 20px)';

            let component_assignee = '';
            for (let u = 0; u < accountModule.reassignmentList.length; u++) {
              // @ts-ignore
              if (accountModule.reassignmentList[u].email == component.assignee.selection_name) {
                component_assignee = `${accountModule.reassignmentList[u].first_name} ${accountModule.reassignmentList[u].last_name}`;
                component_sub_title_p.innerHTML = `${accountModule.reassignmentList[u].first_name} ${accountModule.reassignmentList[u].last_name}`;
              }
            }

            if (FILTER_BY_USERNAME_OPTION != '') {
              // @ts-ignore
              if ((`${FILTER_BY_USERNAME_OPTION!.first_name} ${FILTER_BY_USERNAME_OPTION.last_name}`) != component_assignee) {
                continue;
              }
            }

            component_div.appendChild(component_title_p);
            component_div.appendChild(component_sub_title_p);

            const checklist_div = document.createElement('div');
            if ((component.pre_tasq_checklist != null || component.post_tasq_checklist != null) && (component.pre_tasq_checklist.length > 0 || component.post_tasq_checklist.length > 0)) {
              if (!(Array.isArray(component.pre_tasq_checklist[0])) || !(Array.isArray(component.post_tasq_checklist[0]))) {
                checklist_div.style.width = 'calc(100% - 20px)';
                checklist_div.style.marginLeft = '10px';
                checklist_div.style.height = '25px';
                checklist_div.style.clear = 'both';
                if (component.pre_tasq_checklist.length > 0 || component.post_tasq_checklist.length > 0) {
                  component_div.append(checklist_div);
                }

                for (let b = 0; b < component.pre_tasq_checklist.length; b++) {
                  const pre_tasq = component.pre_tasq_checklist[b];
                  if (Array.isArray(pre_tasq)) {
                    continue;
                  }
                  if (!pre_tasq.complete) {
                    var checklist_bubble = document.createElement('div');
                    checklist_bubble.style.width = '15px';
                    checklist_bubble.style.height = '15px';
                    checklist_bubble.style.borderRadius = '50%';
                    checklist_bubble.style.background = 'rgba(0,0,0,0.2)';
                    checklist_bubble.style.float = 'left';
                    checklist_bubble.style.marginRight = '8px';
                    checklist_bubble.style.marginTop = '5px';
                    checklist_div.appendChild(checklist_bubble);
                  } else {
                    var checklist_span = document.createElement('span');
                    checklist_span.style.width = '12px';
                    checklist_span.style.height = '10px';
                    checklist_span.style.borderRadius = '50%';
                    checklist_span.style.color = 'white';
                    checklist_span.style.fontSize = '17px';
                    checklist_span.style.float = 'left';
                    checklist_span.style.marginRight = '8px';
                    checklist_span.style.marginTop = '3px';
                    checklist_span.classList.add('material-icons');
                    checklist_span.innerHTML = 'check_circle';
                    checklist_div.appendChild(checklist_span);
                  }
                }
              }
            }

            if (component.post_tasq_checklist != null) {
              for (let p = 0; p < component.post_tasq_checklist.length; p++) {
                const post_tasq = component.post_tasq_checklist[p];
                if (!post_tasq.complete) {
                  var checklist_bubble = document.createElement('div');
                  checklist_bubble.style.width = '15px';
                  checklist_bubble.style.height = '15px';
                  checklist_bubble.style.borderRadius = '50%';
                  checklist_bubble.style.background = 'rgba(0,0,0,0.2)';
                  checklist_bubble.style.float = 'right';
                  checklist_bubble.style.marginLeft = '8px';
                  checklist_bubble.style.marginTop = '5px';
                  checklist_div.appendChild(checklist_bubble);
                } else {
                  var checklist_span = document.createElement('span');
                  checklist_span.style.width = '12px';
                  checklist_span.style.height = '12px';
                  checklist_span.style.borderRadius = '50%';
                  checklist_span.style.color = 'white';
                  checklist_span.style.float = 'right';
                  checklist_span.style.fontSize = '17px';
                  checklist_span.style.marginLeft = '8px';
                  checklist_span.style.marginTop = '4px';
                  checklist_span.classList.add('material-icons');
                  checklist_span.innerHTML = 'check_circle';
                  checklist_div.appendChild(checklist_span);
                }
              }
            }

            row_div.appendChild(component_div);
                document.getElementById(route_row_container_id)!.appendChild(row_div);
          }
        }
  }
}

function dragElement(elmnt) {
  let pos1 = 0; let pos2 = 0; let pos3 = 0; let
    pos4 = 0;
  if (document.getElementById(elmnt.id)) {
    /* if present, the header is where you move the DIV from: */
    document.getElementById(elmnt.id)!.onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV: */
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    let target_element = e.target;
    if (!e.target.hasAttribute('isDraggableComponent')) {
      target_element = e.target.parentElement;
    }
    // get the mouse cursor position at startup:
    target_element.style.zIndex = '99999';
    const components = getAllChartComponents(target_element);

    for (let x = 0; x < components.length; x++) {
      components[x].style.zIndex = '999';
      components[x].style.setProperty('z-index', '999', 'important');
    }

    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
    elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
    didDragElement = true;
  }
}
function getAllChartComponents(ignoringDraggedComponent: any = null) {
  const totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]');
  if (ignoringDraggedComponent != null) {
    const otherComponents: any[] = [];
    for (let x = 0; x < totalComponentsList.length; x++) {
      if (ignoringDraggedComponent!.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
        continue;
      }
      otherComponents.push(totalComponentsList[x]);
    }
    return otherComponents;
  }
  return totalComponentsList;
}

var didDragElement = false;

function loadJobDetailsInEdit() {
	  setupJobDetailsData(selectedJobGlobal);
}

async function loadJobDetailsData(e) {
	  let target_element = e.target;
	  if (!e.target.hasAttribute('isDraggableComponent')) {
		  target_element = e.target.parentElement;
	  }

	  const prediction_id = target_element.getAttribute('componentID');

	  let selectedJob = null;
  for (let y = 0; y < UPDATED_JOBS.length; y++) {
    if (UPDATED_JOBS[y].PredictionID == prediction_id) {
      selectedJob = UPDATED_JOBS[y];
    }
  }

	  if (selectedJob != null) {
		  setupJobDetailsData(selectedJob);
	  }
}

function setupJobDetailsData(selectedJob) {
  selectedJobGlobal = selectedJob;
		  document.getElementById('jobDetailsLoadingViewID')!.style.display = 'block';
		  const promises: any[] = [];
		  promises.push(scheduleModule.getComponents());
		  promises.push(scheduleModule.getVendors());
		  promises.push(proceduresModule.getProcedures());
		  promises.push(accountModule.getReassignmentList());
		  // @ts-ignore
		  promises.push(proceduresModule.getWorkflowProcedureResponse({
		    // @ts-ignore
		    workflow_task_id: selectedJob.WorkflowTaskID,
		  }));
		  // @ts-ignore
		  promises.push(proceduresModule.getProcedureByID({
		    // @ts-ignore
		    procedure_id: selectedJob.ProcedureID,
		  }));
		  Promise.all(promises).then(() => {
		    // returned data is in arguments[0], arguments[1], ... arguments[n]
		    // you can process it here

		    setupJobDetailsPopup(selectedJob, proceduresModule.procedure, proceduresModule.workflowProcedureResponse);
				document.getElementById('jobDetailsLoadingViewID')!.style.display = 'none';
		  }, (err) => {
		    // error occurred
		  });
}

function pad(n, width, z) {
  z = z || '0';
  n = `${n}`;
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function getUserDetailsByUsername(username) {
	  for (let x = 0; x < accountModule.reassignmentList.length; x++) {
		  if (accountModule.reassignmentList[x].email == username) {
			  return accountModule.reassignmentList[x];
		  }
	  }
	  return null;
}

function createNewComponentRow() {
  // Component Row
  const procedureStepHiddenComponentsListRow = document.createElement('div');
  procedureStepHiddenComponentsListRow.id = 'procedureStepHiddenComponentsListRow';
  procedureStepHiddenComponentsListRow.classList.add('procedureStepHiddenComponentsListRowClass');
  procedureStepHiddenComponentsListRow.style.width = '100%';
  procedureStepHiddenComponentsListRow.style.height = '80px';
  procedureStepHiddenComponentsListRow.style.overflow = 'hidden';

  // Component input name
  const componentDivName = document.createElement('div');
  componentDivName.classList.add('component-name-element');
  const componentPName = document.createElement('p');
  componentPName.classList.add('component-p');
  componentPName.innerHTML = 'Component name:';
  const componentInputName = document.createElement('input');
  componentInputName.classList.add('component-input');
  componentInputName.classList.add('component-input-name');
  componentDivName.appendChild(componentPName);
  componentDivName.appendChild(componentInputName);

  // Component input length
  const componentDivLength = document.createElement('div');
  componentDivLength.classList.add('component-length-element');
  const componentPLength = document.createElement('p');
  componentPLength.classList.add('component-p');
  componentPLength.innerHTML = 'Length:';
  const componentInputLength = document.createElement('input');
  componentInputLength.classList.add('component-input');
  componentInputLength.classList.add('component-input-length');
  componentDivLength.appendChild(componentPLength);
  componentDivLength.appendChild(componentInputLength);

  // Component input quantity
  const componentDivQuantity = document.createElement('div');
  componentDivQuantity.classList.add('component-quantity-element');
  const componentPQuantity = document.createElement('p');
  componentPQuantity.classList.add('component-p');
  componentPQuantity.innerHTML = 'Quantity:';
  const componentInputQuantity = document.createElement('input');
  componentInputQuantity.classList.add('component-input');
  componentInputQuantity.classList.add('component-input-quantity');
  componentDivQuantity.appendChild(componentPQuantity);
  componentDivQuantity.appendChild(componentInputQuantity);

  // Component outter diameter
  const componentDivDiameter = document.createElement('div');
  componentDivDiameter.classList.add('component-outter-diameter-element');
  const componentPDiameter = document.createElement('p');
  componentPDiameter.classList.add('component-p');
  componentPDiameter.innerHTML = 'OD (Outter Diameter):';
  const componentInputDiameter = document.createElement('input');
  componentInputDiameter.classList.add('component-input');
  componentInputDiameter.classList.add('component-input-diameter');
  componentDivDiameter.appendChild(componentPDiameter);
  componentDivDiameter.appendChild(componentInputDiameter);

  // Component remove
  const componentDivRemove = document.createElement('div');
  componentDivRemove.classList.add('component-remove-element');
  const componentPRemove = document.createElement('p');
  componentPRemove.classList.add('material-icons');
  componentPRemove.innerHTML = 'highlight_off';
  componentDivRemove.appendChild(componentPRemove);

  // Step actions
  componentPRemove.onclick = (function (procedureStepHiddenComponentsListRow) {
    return function () {
				  procedureStepHiddenComponentsListRow.remove();
    };
  }(procedureStepHiddenComponentsListRow));

  procedureStepHiddenComponentsListRow.appendChild(componentDivName);
  procedureStepHiddenComponentsListRow.appendChild(componentDivLength);
  procedureStepHiddenComponentsListRow.appendChild(componentDivQuantity);
  procedureStepHiddenComponentsListRow.appendChild(componentDivDiameter);
  procedureStepHiddenComponentsListRow.appendChild(componentDivRemove);

  return procedureStepHiddenComponentsListRow;
}

function setupJobDetailsPopup(selectedJob, procedure, workflowProcedureResponse) {
  const operatorDetails = getUserDetailsByUsername(selectedJob.JobOperator);
  const engineerDetails = getUserDetailsByUsername(selectedJob.Username);
	// Set job details title
	document.getElementById('jobDetailsTextOperatorValueID')!.innerHTML = `Operator - ${operatorDetails?.first_name} ${operatorDetails?.last_name}`;

	// Set duration text
	const selectedStartDate = new Date(selectedJob.SelectedStartDate);
	const selectedEndDate = new Date(selectedJob.SelectedEndDate);
	const selectedStartDateString = `${pad((selectedStartDate.getMonth() + 1), 2, '0')}/${pad((selectedStartDate.getDate()), 2, '0')}/${selectedStartDate.getFullYear()}`;
	const selectedEndDateString = `${pad((selectedEndDate.getMonth() + 1), 2, '0')}/${pad((selectedEndDate.getDate()), 2, '0')}/${selectedEndDate.getFullYear()}`;
	document.getElementById('jobDetailsTextEstimatedDurationValueID')!.innerHTML = `${selectedStartDateString} to ${selectedEndDateString}`;

	document.getElementById('jobDetailsTextNodeIDValueID')!.innerHTML = selectedJob.NodeID;

	document.getElementById('jobDetailsTextTitleValueID')!.innerHTML = selectedJob.JobName;
	document.getElementById('jobDetailsTextIssueSummaryValueID')!.innerHTML = selectedJob.JobSummary;

	document.getElementById('jobDetailsTextComponentsContainerID');
	// document.getElementById("jobDetailsCurrentTimeValueID")!.innerHTML = pad((new Date().getMonth() + 1), 2, '0') + "/" + pad((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
	document.getElementById('jobDetailsContactOperatorValueID')!.innerHTML = `${operatorDetails?.first_name} ${operatorDetails?.last_name}`;
	document.getElementById('jobDetailsContactOperatorPhoneNumberValueID')!.innerHTML = operatorDetails != null ? operatorDetails.phone_number : '';
	document.getElementById('jobDetailsContactEngineerValueID')!.innerHTML = `${engineerDetails?.first_name} ${engineerDetails?.last_name}`;
	document.getElementById('jobDetailsContactEngineerPhoneNumberValueID')!.innerHTML = engineerDetails != null ? engineerDetails.phone_number : '';
	document.getElementById('jobDetailsFailureTypeValueID')!.innerHTML = procedure.ProcedureName;

	const jobDetailsProcedureStepsDivID = document.getElementById('jobDetailsProcedureStepsDivID');
	for (let r = 0; r < procedure.ProcedureJson.steps.length; r++) {
	  const step = procedure.ProcedureJson.steps[r];

	  // Visible content
	  const procedureStepContentWrapper = document.createElement('div');
	  procedureStepContentWrapper.id = 'procedureStepContentWrapper';

	  const procedureStepVisibleContentWrapper = document.createElement('div');
	  procedureStepVisibleContentWrapper.id = 'procedureStepVisibleContentWrapper';

	  const procedureStepTitleWrapper = document.createElement('div');
	  procedureStepTitleWrapper.id = 'procedureStepTitleWrapper';

	  const procedureStepTitleLabelContainerWrapper = document.createElement('div');
	  procedureStepTitleLabelContainerWrapper.id = 'procedureStepTitleLabelContainerWrapper';

	  const procedureStepCountLabel = document.createElement('p');
	  procedureStepCountLabel.id = 'procedureStepCountLabel';
	  procedureStepCountLabel.classList.add('uppercase');
	  procedureStepCountLabel.classList.add('step_label');
	  procedureStepCountLabel.classList.add('procedureStepCountLabelClass');
	  procedureStepCountLabel.innerHTML = `Step ${(r + 1).toString()}`;

	  const procedureStepTitleLabel = document.createElement('p');
	  procedureStepTitleLabel.id = 'procedureStepTitleLabel';
	  procedureStepTitleLabel.classList.add('step_title');
	  procedureStepTitleLabel.innerHTML = step.title;

	  const procedureStepSummaryLabel = document.createElement('p');
	  procedureStepSummaryLabel.id = 'procedureStepSummaryLabel';
	  procedureStepSummaryLabel.classList.add('step_title');
	  procedureStepSummaryLabel.innerHTML = step.text;

	  // Hidden content
	  const procedureStepHiddenContentWrapper = document.createElement('div');
	  procedureStepHiddenContentWrapper.id = 'procedureStepHiddenContentWrapper';
	  procedureStepHiddenContentWrapper.classList.add('step_title');
	  procedureStepHiddenContentWrapper.style.display = 'none';

	  const procedureStepHiddenSelectActionTitleLabel = document.createElement('p');
	  procedureStepHiddenSelectActionTitleLabel.id = 'procedureStepHiddenSelectActionTitleLabel';
	  procedureStepHiddenSelectActionTitleLabel.classList.add('step_title');
	  procedureStepHiddenSelectActionTitleLabel.classList.add('uppercase');

	  // Went Smooth
	  const procedureStepHiddenWentSmoothWrapper = document.createElement('div');
	  procedureStepHiddenWentSmoothWrapper.id = 'procedureStepHiddenWentSmoothWrapper';
	  procedureStepHiddenWentSmoothWrapper.classList.add('step_title');

	  const procedureStepHiddenWentSmoothButton = document.createElement('button');
	  procedureStepHiddenWentSmoothButton.id = 'procedureStepHiddenWentSmoothButton';
	  procedureStepHiddenWentSmoothButton.classList.add('step_title');
	  procedureStepHiddenWentSmoothButton.classList.add('procedureStepHiddenWentSmoothButtonClass');
	  procedureStepHiddenWentSmoothButton.innerHTML = 'Went smooth';

	  const procedureStepHiddenWentSmoothDescription = document.createElement('p');
	  procedureStepHiddenWentSmoothDescription.id = 'procedureStepHiddenWentSmoothDescription';
	  procedureStepHiddenWentSmoothDescription.classList.add('step_title');
	  procedureStepHiddenWentSmoothDescription.innerHTML = '';

	  // Report Issue
	  const procedureStepHiddenReportIssueWrapper = document.createElement('div');
	  procedureStepHiddenReportIssueWrapper.id = 'procedureStepHiddenReportIssueWrapper';
	  procedureStepHiddenReportIssueWrapper.classList.add('step_title');

	  const procedureStepHiddenReportIssueButton = document.createElement('button');
	  procedureStepHiddenReportIssueButton.id = 'procedureStepHiddenReportIssueButton';
	  procedureStepHiddenReportIssueButton.classList.add('step_title');
	  procedureStepHiddenReportIssueButton.classList.add('reportIssueButton');
	  procedureStepHiddenReportIssueButton.classList.add('procedureStepHiddenReportIssueButtonClass');
	  procedureStepHiddenReportIssueButton.innerHTML = 'Report issue';

	  const procedureStepHiddenReportIssueDescription = document.createElement('p');
	  procedureStepHiddenReportIssueDescription.id = 'procedureStepHiddenReportIssueDescription';
	  procedureStepHiddenReportIssueDescription.classList.add('step_title');
	  procedureStepHiddenReportIssueDescription.innerHTML = '';

	  // Report Issue Comment

	  const procedureStepHiddenReportIssueComment = document.createElement('textarea');
	  procedureStepHiddenReportIssueComment.id = 'procedureStepHiddenReportIssueComment';
	  procedureStepHiddenReportIssueComment.classList.add('procedureStepHiddenReportIssueCommentClass');
	  procedureStepHiddenReportIssueComment.placeholder = 'Add comment...';
	  procedureStepHiddenReportIssueComment.style.display = 'none';

	  // Component Section
	  const procedureStepHiddenComponentsListWrapper = document.createElement('div');
	  procedureStepHiddenComponentsListWrapper.id = 'procedureStepHiddenComponentsListWrapper';

	  // Component Rows Container
	  const procedureStepHiddenComponentsListContainer = document.createElement('div');
	  procedureStepHiddenComponentsListContainer.id = 'procedureStepHiddenComponentsListContainer';
	  procedureStepHiddenComponentsListContainer.classList.add('procedureStepHiddenComponentsListContainerClass');
	  procedureStepHiddenComponentsListContainer.style.width = '100%';
	  procedureStepHiddenComponentsListContainer.style.overflow = 'hidden';

	  // Component Button
	  const procedureStepHiddenComponentButtonWrapper = document.createElement('div');
	  procedureStepHiddenComponentButtonWrapper.id = 'procedureStepHiddenComponentButtonWrapper';

	  const procedureStepHiddenComponentButtonDiv = document.createElement('div');
	  procedureStepHiddenComponentButtonDiv.id = 'procedureStepHiddenComponentButtonDiv';
	  procedureStepHiddenComponentButtonDiv.classList.add('step_title');

	  const procedureStepHiddenComponentButtonIcon = document.createElement('span');
	  procedureStepHiddenComponentButtonIcon.id = 'procedureStepHiddenComponentButtonIcon';
	  procedureStepHiddenComponentButtonIcon.classList.add('material-icons');
	  procedureStepHiddenComponentButtonIcon.classList.add('step_title');
	  procedureStepHiddenComponentButtonIcon.innerHTML = 'add_circle';

	  const procedureStepHiddenComponentButtonLabel = document.createElement('p');
	  procedureStepHiddenComponentButtonLabel.id = 'procedureStepHiddenComponentButtonLabel';
	  procedureStepHiddenComponentButtonLabel.innerHTML = 'Add Component';

	  // Save Button
	  const procedureStepHiddenDurationInput = document.createElement('input');
	  procedureStepHiddenDurationInput.id = 'procedureStepHiddenDurationInput';
	  procedureStepHiddenDurationInput.classList.add('procedureStepHiddenDurationInputClass');
	  procedureStepHiddenDurationInput.placeholder = 'Duration (hours)';

	  const procedureStepHiddenSaveButtonWrapper = document.createElement('div');
	  procedureStepHiddenSaveButtonWrapper.id = 'procedureStepHiddenSaveButtonWrapper';

	  const procedureStepHiddenSaveButton = document.createElement('p');
	  procedureStepHiddenSaveButton.id = 'procedureStepHiddenSaveButton';
	  procedureStepHiddenSaveButton.classList.add('save-schedule-step');
	  procedureStepHiddenSaveButton.innerHTML = 'Save';

	  procedureStepHiddenSaveButtonWrapper.appendChild(procedureStepHiddenSaveButton);

	  // Select procedure response option
	  procedureStepHiddenWentSmoothButton.onclick = (function (procedureStepHiddenReportIssueWrapper, procedureStepHiddenReportIssueComment, procedureStepHiddenWentSmoothButton, procedureStepHiddenReportIssueButton) {
	    return function () {
				  procedureStepHiddenWentSmoothButton.style.background = '#376af5';
				  procedureStepHiddenWentSmoothButton.style.color = 'white';
				  procedureStepHiddenWentSmoothButton.style.opacity = '1';
				  procedureStepHiddenWentSmoothButton.style.border = 'solid 1px rgba(0,0,0,0)';

				  procedureStepHiddenReportIssueButton.style.background = 'white';
				  procedureStepHiddenReportIssueButton.style.color = '#222b44';
				  procedureStepHiddenReportIssueButton.style.opacity = '0.5';
				  procedureStepHiddenReportIssueButton.style.border = 'solid 1px #222b44';

				  procedureStepHiddenWentSmoothButton.classList.add('procedureStepHiddenHighlightButton');
				  procedureStepHiddenReportIssueButton.classList.remove('procedureStepHiddenHighlightButton');

	      procedureStepHiddenReportIssueComment.style.display = 'none';
	      // procedureStepHiddenReportIssueComment.remove()
	    };
	  }(procedureStepHiddenReportIssueWrapper, procedureStepHiddenReportIssueComment, procedureStepHiddenWentSmoothButton, procedureStepHiddenReportIssueButton));

	  procedureStepHiddenReportIssueButton.onclick = (function (procedureStepHiddenReportIssueWrapper, procedureStepHiddenReportIssueComment, procedureStepHiddenWentSmoothButton, procedureStepHiddenReportIssueButton) {
	    return function () {
				  procedureStepHiddenReportIssueButton.style.background = '#376af5';
				  procedureStepHiddenReportIssueButton.style.color = 'white';
				  procedureStepHiddenReportIssueButton.style.opacity = '1';
				  procedureStepHiddenReportIssueButton.style.border = 'solid 1px rgba(0,0,0,0)';

				  procedureStepHiddenWentSmoothButton.style.background = 'white';
				  procedureStepHiddenWentSmoothButton.style.color = '#222b44';
				  procedureStepHiddenWentSmoothButton.style.opacity = '0.5';
				  procedureStepHiddenWentSmoothButton.style.border = 'solid 1px #222b44';

				  procedureStepHiddenReportIssueButton.classList.add('procedureStepHiddenHighlightButton');
				  procedureStepHiddenWentSmoothButton.classList.remove('procedureStepHiddenHighlightButton');

	      procedureStepHiddenReportIssueComment.style.display = 'block';
	    };
	  }(procedureStepHiddenReportIssueWrapper, procedureStepHiddenReportIssueComment, procedureStepHiddenWentSmoothButton, procedureStepHiddenReportIssueButton));

	  // Step actions
	  procedureStepHiddenComponentButtonDiv.onclick = (function (procedureStepHiddenComponentsListContainer) {
	    return function () {
				  procedureStepHiddenComponentsListContainer.appendChild(createNewComponentRow());
	    };
	  }(procedureStepHiddenComponentsListContainer));

	  // Step actions
	  procedureStepVisibleContentWrapper.onclick = (function (procedureStepHiddenContentWrapper) {
	    return function () {
				  if (procedureStepHiddenContentWrapper.style.display == 'none') {
	        procedureStepHiddenContentWrapper.style.display = 'block';
				  } else {
					  procedureStepHiddenContentWrapper.style.display = 'none';
				  }
	    };
	  }(procedureStepHiddenContentWrapper));

		// Add to page
		jobDetailsProcedureStepsDivID!.appendChild(procedureStepContentWrapper);

		procedureStepContentWrapper.appendChild(procedureStepVisibleContentWrapper);
		// Main container
		procedureStepVisibleContentWrapper.appendChild(procedureStepTitleWrapper);
		procedureStepContentWrapper.appendChild(procedureStepHiddenContentWrapper);

		// Visible content
		procedureStepTitleWrapper.appendChild(procedureStepTitleLabelContainerWrapper);
		procedureStepTitleLabelContainerWrapper.appendChild(procedureStepCountLabel);
		procedureStepTitleLabelContainerWrapper.appendChild(procedureStepTitleLabel);
		procedureStepTitleWrapper.appendChild(procedureStepSummaryLabel);

		// Hidden content
		procedureStepHiddenContentWrapper.appendChild(procedureStepHiddenSelectActionTitleLabel);

		procedureStepHiddenWentSmoothWrapper.appendChild(procedureStepHiddenWentSmoothButton);
		procedureStepHiddenWentSmoothWrapper.appendChild(procedureStepHiddenWentSmoothDescription);

		procedureStepHiddenReportIssueWrapper.appendChild(procedureStepHiddenReportIssueButton);
		procedureStepHiddenReportIssueWrapper.appendChild(procedureStepHiddenReportIssueDescription);

		// Component button
		procedureStepHiddenComponentButtonWrapper.appendChild(procedureStepHiddenComponentsListContainer);
		procedureStepHiddenComponentButtonWrapper.appendChild(procedureStepHiddenComponentButtonDiv);
		procedureStepHiddenComponentButtonDiv.appendChild(procedureStepHiddenComponentButtonIcon);
		procedureStepHiddenComponentButtonDiv.appendChild(procedureStepHiddenComponentButtonLabel);

		procedureStepHiddenContentWrapper.appendChild(procedureStepHiddenWentSmoothWrapper);
		procedureStepHiddenContentWrapper.appendChild(procedureStepHiddenReportIssueWrapper);
		procedureStepHiddenContentWrapper.appendChild(procedureStepHiddenComponentButtonWrapper);
		procedureStepHiddenContentWrapper.appendChild(procedureStepHiddenDurationInput);
		procedureStepHiddenContentWrapper.appendChild(procedureStepHiddenSaveButtonWrapper);

		procedureStepHiddenReportIssueWrapper.appendChild(procedureStepHiddenReportIssueComment);

		procedureStepHiddenSaveButton.onclick = (function (procedure, selectedJob, procedureStepContentWrapper, step, procedureStepHiddenSaveButton, procedureStepHiddenSaveButtonWrapper, procedureStepHiddenContentWrapper, procedureStepCountLabel) {
		  return function () {
				  postScheduledJobProcedureStepResponse(procedure, selectedJob, procedureStepContentWrapper, step, procedureStepHiddenSaveButton, procedureStepHiddenSaveButtonWrapper, procedureStepHiddenContentWrapper, procedureStepCountLabel);
		  };
		}(procedure, selectedJob, procedureStepContentWrapper, step, procedureStepHiddenSaveButton, procedureStepHiddenSaveButtonWrapper, procedureStepHiddenContentWrapper, procedureStepCountLabel));

		for (let t = 0; t < workflowProcedureResponse.length; t++) {
		  if (workflowProcedureResponse[t].StepID == step.id) {
		    const stepResponse = workflowProcedureResponse[t];
		    // UPDATE ELEMENTS TO WHAT THEY WERE SAVED AS
		    if (stepResponse.ResponseOption == 'report issue') {
		      procedureStepHiddenReportIssueButton.style.background = '#376af5';
		      procedureStepHiddenReportIssueButton.style.color = 'white';
		      procedureStepHiddenReportIssueButton.style.opacity = '1';
		      procedureStepHiddenReportIssueButton.style.border = 'solid 1px rgba(0,0,0,0)';
		      procedureStepHiddenReportIssueButton.classList.add('procedureStepHiddenHighlightButton');
		      procedureStepHiddenReportIssueComment.style.display = 'block';
		      procedureStepHiddenReportIssueComment.innerHTML = stepResponse.ResponseComment;
		    } else if (stepResponse.ResponseOption == 'went smooth') {
		      procedureStepHiddenWentSmoothButton.style.background = '#376af5';
		      procedureStepHiddenWentSmoothButton.style.color = 'white';
		      procedureStepHiddenWentSmoothButton.style.opacity = '1';
		      procedureStepHiddenWentSmoothButton.style.border = 'solid 1px rgba(0,0,0,0)';
		      procedureStepHiddenWentSmoothButton.classList.add('procedureStepHiddenHighlightButton');
		      procedureStepHiddenReportIssueComment.style.display = 'none';
		    }

		    if (stepResponse.CompletionDuration != null) {
		      procedureStepHiddenDurationInput.value = stepResponse.CompletionDuration;
		    }

		    // Step actions
		    for (let q = 0; q < stepResponse.ComponentsArray[0].length; q++) {
		      const component = stepResponse.ComponentsArray[0][q];
		      const newComponent = createNewComponentRow();
		      procedureStepHiddenComponentsListContainer.appendChild(newComponent);
		      if ('ComponentName' in component) {
		        // @ts-ignore
		        newComponent.getElementsByClassName('component-input-name')[0].value = component.ComponentName;
		      }
		      if ('Diameter' in component) {
		        // @ts-ignore
		        newComponent.getElementsByClassName('component-input-diameter')[0].value = component.Diameter;
		      }
		      if ('Length' in component) {
		        // @ts-ignore
		        newComponent.getElementsByClassName('component-input-length')[0].value = component.Length;
		      }
		      if ('Quantity' in component) {
		        // @ts-ignore
		        newComponent.getElementsByClassName('component-input-quantity')[0].value = component.Quantity;
		      }
		    }

		    // var componentsListElement = procedureStepHiddenComponentsListContainer // Contains the component rows if any where added

		    const procedureStepTitleElement = procedureStepCountLabel;
		    procedureStepTitleElement.style.background = '#376af5';

		    break;
		  }
		}
	}
}

async function postScheduledJobProcedureStepResponse(procedure, selectedJob, procedureStepContentWrapper, step, procedureStepHiddenSaveButton, procedureStepHiddenSaveButtonWrapper, procedureStepHiddenContentWrapper, procedureStepCountLabel) {
	  // Overlay loading div
	  const saveProcedureResponseLoadingContainerDiv = document.createElement('div');
	  saveProcedureResponseLoadingContainerDiv.id = 'saveProcedureResponseLoadingContainerDiv';
	  saveProcedureResponseLoadingContainerDiv.style.position = 'absolute';

	  const saveProcedureResponseLoadingDiv = document.createElement('div');
	  saveProcedureResponseLoadingDiv.id = 'saveProcedureResponseLoadingDiv';
	  saveProcedureResponseLoadingDiv.style.fontSize = '4px';
	  saveProcedureResponseLoadingDiv.classList.add('loader-small');
	  saveProcedureResponseLoadingDiv.innerHTML = 'Loading...';
  //   <div style="font-size: 4px;" class="loader">Loading...</div>

  // Add loading view
  saveProcedureResponseLoadingContainerDiv.appendChild(saveProcedureResponseLoadingDiv);
  procedureStepHiddenSaveButtonWrapper.appendChild(saveProcedureResponseLoadingContainerDiv);

  // var wentSmoothElement = procedureStepContentWrapper.getElementsByClassName("procedureStepHiddenWentSmoothButtonClass")[0] // Went smooth button
  const reportIssueElement = procedureStepContentWrapper.getElementsByClassName('procedureStepHiddenReportIssueButtonClass')[0]; // Report issue
  const reportIssueCommentElement = procedureStepContentWrapper.getElementsByClassName('procedureStepHiddenReportIssueCommentClass')[0]; // Report issue
  const componentsListElement = procedureStepContentWrapper.getElementsByClassName('procedureStepHiddenComponentsListContainerClass')[0]; // Contains the component rows if any where added
  const durationElement = procedureStepContentWrapper.getElementsByClassName('procedureStepHiddenDurationInputClass')[0]; // Duration

  const nodeID = selectedJob.NodeID;
  const procedureID = procedure.ProcedureID;
  let procedureType = procedure.ProcedureType;
  const responseComment = reportIssueCommentElement.value;
  const completionDuration = durationElement.value;
  const stepID = step.id;
  const username = accountModule.user.email;
  const workflowID = selectedJob.WorkflowTaskID;

  const componentsElemList = componentsListElement.getElementsByClassName('procedureStepHiddenComponentsListRowClass');

  const componentsList: any[] = [];
  for (let t = 0; t < componentsElemList.length; t++) {
    const component_name = componentsElemList[t].getElementsByClassName('component-input-name')[0].value;
    const component_length = componentsElemList[t].getElementsByClassName('component-input-length')[0].value;
    const component_quantity = componentsElemList[t].getElementsByClassName('component-input-quantity')[0].value;
    const component_diameter = componentsElemList[t].getElementsByClassName('component-input-diameter')[0].value;

    const component_dict: any = {
      ComponentID: uuidv4(),
      ComponentName: component_name,
    };
    if (component_length != null && component_length != '') {
      component_dict.Length = component_length;
    }
    if (component_quantity != null && component_quantity != '') {
      component_dict.Quantity = component_quantity;
    }
    if (component_diameter != null && component_diameter != '') {
      component_dict.Diameter = component_diameter;
    }

    componentsList.push(component_dict);
  }

  let responseOption = 'went smooth';
  if (reportIssueElement.classList.contains('procedureStepHiddenHighlightButton')) {
    responseOption = 'report issue';
  }

  if (procedureType == 'Generic') {
    procedureType = 'Generic';
  } else if (procedureType == 'WellSpecific') {
    procedureType = 'WellSpecific';
  }

  // @ts-ignore
  await proceduresModule.postProcedureResponse({
    CompletionDuration: completionDuration,
    ComponentsArray: JSON.stringify(componentsList),
    NodeID: nodeID,
    ProcedureID: procedureID,
    ProcedureType: procedureType,
    ResponseComment: responseComment,
    ResponseOption: responseOption,
    StepID: stepID,
    Username: username.toLowerCase(),
    WorkflowID: workflowID,
  });

  saveProcedureResponseLoadingContainerDiv.remove();
  procedureStepHiddenContentWrapper.style.display = 'none';
  procedureStepCountLabel.style.background = 'rgb(0, 120, 233)';
}

function closeDragElement(e) {
  /* stop moving when mouse button is released: */
  document.onmouseup = null;
  document.onmousemove = null;
  if (!didDragElement) {
        document.getElementById('jobDetailsPopupID')!.style.display = 'block';
        // document.getElementById("jobDetailsPopupCloseID")!.addEventListener("click", closeJobDetailsPage);
        loadJobDetailsData(e);
        handleClickedComponent(e);
        return;
  }
  const components = getAllChartComponents(e.target);

  for (let x = 0; x < components.length; x++) {
    components[x].style.zIndex = '99999';
  }
  didDragElement = false;
  handleDraggedComponent(e);
}

function closeJobDetailsPage() {
  selectedJobGlobal = null;
    document.getElementById('jobDetailsPopupID')!.style.display = 'none';
}

function snapToClosestDayXValue(target_element) {
  // CHART_DAY_WIDTH
  const target_element_pos = getPos(target_element, 'DRAGGED');
  const target_element_min_x = target_element_pos.x;

  const closest_day = (Math.floor(((target_element_min_x - (CHART_DAY_WIDTH / 1.5)) - PIXELS_TO_CURRENT_DAY) / CHART_DAY_WIDTH) + CHART_START_DATETIME.getDate()) + 1;
//   var closest_day = (Math.floor(((target_element_min_x - (CHART_DAY_WIDTH / 2.5)) / CHART_DAY_WIDTH))) + 1
  target_element.style.left = `${(closest_day * CHART_DAY_WIDTH) - ((CHART_START_DATETIME.getDate() - 1) * CHART_DAY_WIDTH)}px`;
}

function snapDraggedElementToRigRow(target_element, draggedElementNewRouteRowID) {
  const routeComponentRowID = draggedElementNewRouteRowID.replaceAll('route_row_', 'route_component_row_');
  // var rigRowElement = document.getElementById(draggedElementNewRouteRowID)
  target_element.style.top = '10px';

        document.getElementById(routeComponentRowID)!.appendChild(target_element);
        snapToClosestDayXValue(target_element);
}

function getOffset(el) {
  let _x = 0;
  let _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}
// var x = getOffset( document.getElementById('yourElId') ).left;

function getRigNameFromRigID(rigID) {
  for (let x = 0; x < ROUTES.length; x++) {
    if (ROUTES[x].RouteID == rigID || ROUTES[x].RouteID.toLowerCase() == rigID.toLowerCase()) {
      return ROUTES[x].RouteName;
    }
  }
}

function handleDraggedComponent(e) {
  let target_element = e.target;
  if (!e.target.hasAttribute('isDraggableComponent')) {
    target_element = e.target.parentElement;
  }
	console.log("target_element: ")
	console.log(target_element)
  const draggedElementNewRouteRowID = getClosestRigRowIDOnDragEnd(target_element);
//   const otherComponents: any[] = [];
//   const totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]');
//   for (let x = 0; x < totalComponentsList.length; x++) {
//     if (target_element.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
//       continue;
//     }
//     if (draggedElementNewRouteRowID == getClosestRigRowIDOnDragEnd(totalComponentsList[x])) {
//       otherComponents.push(totalComponentsList[x]);
//     }
//   }

  // if (otherComponents.length == 0) {
  snapDraggedElementToRigRow(target_element, draggedElementNewRouteRowID);
  // }
  const componentsToUpdate: any[] = [];
  const newTargetElementDateRange = getComponentDateRangeFromChartLocation(target_element);
  componentsToUpdate.push({
    PredictionID: target_element.getAttribute('componentID'),
    StartDate: newTargetElementDateRange[0],
    EndDate: newTargetElementDateRange[1],
	// @ts-ignore
    RouteID: draggedElementNewRouteRowID.split("_test_this_")[1],
  });
//   if (otherComponents.length > 0) {
//     // Overlaps and preceeding
//     const overlap_proceeding: any[] = [];
//     const non_overlap_proceeding: any[] = [];
//     const overlap_following: any[] = [];
//     const non_overlap_following: any[] = [];

//     const target_element_pos = getPos(target_element, 'DRAGGED');
//     const target_element_center_x = getElementCenterPosition(target_element, 'DRAGGED').x;
//     const target_element_max_x = target_element_pos.x + target_element.getBoundingClientRect().width;
//     const target_element_min_x = target_element_pos.x;
//     for (var y = 0; y < otherComponents.length; y++) {
//       var other_component_pos = getPos(otherComponents[y], 'OTHER');
//       var other_element_center_x = getElementCenterPosition(otherComponents[y], 'OTHER').x;
//       var other_element_max_x = other_component_pos.x + otherComponents[y].getBoundingClientRect().width;
//       var other_element_min_x = other_component_pos.x;

//       if (other_element_center_x < target_element_center_x && other_element_max_x > target_element_min_x) {
//         overlap_proceeding.push(otherComponents[y]);
//       } else if (other_element_center_x > target_element_center_x && other_element_min_x < target_element_max_x) {
//         overlap_following.push(otherComponents[y]);
//       } else if (other_element_center_x < target_element_center_x) {
//         non_overlap_proceeding.push(otherComponents[y]);
//       } else if (other_element_center_x > target_element_center_x) {
//         non_overlap_following.push(otherComponents[y]);
//       }
//     }
//     if (overlap_proceeding.length) {
//       // Move every element in overlap_proceeding and non_overlap_proceeding to the left ((other_element_max_x - target_element_min_x) + "px")
//       var overlap_following_total = 0;
//       for (var g = 0; g < overlap_proceeding.length; g++) {
//         var other_component_pos = getPos(overlap_proceeding[g], 'OTHER');
//         var other_element_center_x = getElementCenterPosition(overlap_proceeding[y = g], 'OTHER').x;
//         var other_element_max_x = other_component_pos.x + overlap_proceeding[g].getBoundingClientRect().width;
//         var other_element_min_x = other_component_pos.x;
//         overlap_following_total = (other_element_max_x - target_element_min_x);
//         overlap_proceeding[g].style.left = `${parseInt(overlap_proceeding[g].style.left.replace('px', '')) - (other_element_max_x - target_element_min_x)}px`;

//         var newShiftedElementDateRange = getComponentDateRangeFromChartLocation(overlap_proceeding[g]);
//         componentsToUpdate.push({
//           PredictionID: overlap_proceeding[g].getAttribute('componentID'),
//           StartDate: newShiftedElementDateRange[0],
//           EndDate: newShiftedElementDateRange[1],
//           RouteID: draggedElementNewRouteRowID,
//         });
//       }
//       for (var g = 0; g < non_overlap_proceeding.length; g++) {
//         var other_component_pos = getPos(non_overlap_proceeding[g], 'OTHER');
//         var other_element_center_x = getElementCenterPosition(non_overlap_proceeding[g], 'OTHER').x;
//         var other_element_max_x = other_component_pos.x + non_overlap_proceeding[g].getBoundingClientRect().width;
//         var other_element_min_x = other_component_pos.x;
//         non_overlap_proceeding[g].style.left = `${parseInt(non_overlap_proceeding[g].style.left.replace('px', '')) - overlap_following_total}px`;

// 			 		var newShiftedElementDateRange = getComponentDateRangeFromChartLocation(non_overlap_proceeding[g]);
//         componentsToUpdate.push({
//           PredictionID: non_overlap_proceeding[g].getAttribute('componentID'),
//           StartDate: newShiftedElementDateRange[0],
//           EndDate: newShiftedElementDateRange[1],
//           RouteID: draggedElementNewRouteRowID,
//         });
// 			 	}
//     }
//     if (overlap_following.length) {
//       var overlap_following_total = 0;
//       for (var g = 0; g < overlap_following.length; g++) {
//         var other_component_pos = getPos(overlap_following[g], 'OTHER');
//         var other_element_center_x = getElementCenterPosition(overlap_following[g], 'OTHER').x;
//         var other_element_max_x = other_component_pos.x + overlap_following[g].getBoundingClientRect().width;
//         var other_element_min_x = other_component_pos.x;
//         overlap_following_total = (target_element_max_x - other_element_min_x);
//         overlap_following[g].style.left = `${parseInt(overlap_following[g].style.left.replace('px', '')) + (target_element_max_x - other_element_min_x)}px`;

//         var newShiftedElementDateRange = getComponentDateRangeFromChartLocation(overlap_following[g]);
//         componentsToUpdate.push({
//           PredictionID: overlap_following[g].getAttribute('componentID'),
//           StartDate: newShiftedElementDateRange[0],
//           EndDate: newShiftedElementDateRange[1],
//           RouteID: draggedElementNewRouteRowID,
//         });
//       }
//       for (var g = 0; g < non_overlap_following.length; g++) {
//         var other_component_pos = getPos(non_overlap_following[g], 'OTHER');
//         var other_element_center_x = getElementCenterPosition(non_overlap_following[g], 'OTHER').x;
//         var other_element_max_x = other_component_pos.x + non_overlap_following[g].getBoundingClientRect().width;
//         var other_element_min_x = other_component_pos.x;
//         non_overlap_following[g].style.left = `${parseInt(non_overlap_following[g].style.left.replace('px', '')) + overlap_following_total}px`;

//         var newShiftedElementDateRange = getComponentDateRangeFromChartLocation(non_overlap_following[g]);
//         componentsToUpdate.push({
//           PredictionID: non_overlap_following[g].getAttribute('componentID'),
//           StartDate: newShiftedElementDateRange[0],
//           EndDate: newShiftedElementDateRange[1],
//           RouteID: draggedElementNewRouteRowID,
//         });
//       }
//     }
//   }
  updateComponentRanges(componentsToUpdate);
}

function updateComponentRanges(componentsToUpdate) {
  const promises: any[] = [];
  for (let y = 0; y < componentsToUpdate.length; y++) {
    var prediction_id = componentsToUpdate[y].PredictionID;
    var route_id = componentsToUpdate[y].RouteID;
    const start_date = componentsToUpdate[y].StartDate;
    const end_date = componentsToUpdate[y].EndDate;
    var route_id = componentsToUpdate[y].RouteID.replace('route_row_', '');
    var route_name = getRigNameFromRigID(route_id);

    var startDateString = `${start_date.getUTCFullYear()}-${pad(start_date.getUTCMonth() + 1, 2, '0')}-${pad(start_date.getUTCDate(), 2, '0')}T${pad(start_date.getUTCHours(), 2, '0')}:${pad(start_date.getUTCMinutes(), 2, '0')}:${pad(start_date.getUTCSeconds(), 2, '0')}`;
    var endDateString = `${end_date.getUTCFullYear()}-${pad(end_date.getUTCMonth() + 1, 2, '0')}-${pad(end_date.getUTCDate(), 2, '0')}T${pad(end_date.getUTCHours(), 2, '0')}:${pad(end_date.getUTCMinutes(), 2, '0')}:${pad(end_date.getUTCSeconds(), 2, '0')}`;


    promises.push(scheduleModule.updateScheduledJobDateRanges({
      prediction_id,
      selected_end_date: endDateString,
      selected_start_date: startDateString,
      rig_id: route_id,
      rig_name: route_name,
    }));
  }

  Promise.all(promises).then(() => {
    // returned data is in arguments[0], arguments[1], ... arguments[n]
    // you can process it here
	window.location.reload();
    // document.getElementById("showSaveSuccessfulNotification")!.style.visibility = "visible"

    let opacity = 0;
    function FadeInFunction() {
      if (opacity < 1) {
        opacity += 0.1;
        setTimeout(() => { FadeInFunction(); }, 30);
      }
				document.getElementById('showSaveSuccessfulNotification')!.style.opacity = opacity.toString();
    }
    function FadeOutFunction() {
      if (opacity > 0) {
        opacity -= 0.1;
        setTimeout(() => { FadeOutFunction(); }, 30);
      }
				document.getElementById('showSaveSuccessfulNotification')!.style.opacity = opacity.toString();
    }

    FadeInFunction();

    sleep(4000).then(() => {
      FadeOutFunction();
    });
    UPDATED_JOBS = UPDATED_JOBS.map((s) => {
      if (s.PredictionID == prediction_id) {
        s.SelectedEndDate = endDateString;
        s.SelectedStartDate = startDateString;
        s.RigName = route_id;
        s.RigID = route_name;
      }
      return s;
    });
  }, (err) => {
    // error occurred
  });
}

function getComponentDateRangeFromChartLocation(component) {
  const target_element_pos = getPos(component, 'DRAGGED');
  const target_element_min_x = target_element_pos.x;
  const target_element_max_x = target_element_pos.x + component.getBoundingClientRect().width;

  const new_start_of_day = Math.floor((target_element_min_x - PIXELS_TO_CURRENT_DAY) / CHART_DAY_WIDTH) + CHART_START_DATETIME.getDate();
  const new_end_of_day = Math.floor((target_element_max_x - PIXELS_TO_CURRENT_DAY) / CHART_DAY_WIDTH) + CHART_START_DATETIME.getDate();

  const firstDayStart = new Date(CHART_START_DATETIME.getFullYear(), CHART_START_DATETIME.getMonth(), 1);
  const firstDayEnd = new Date(CHART_START_DATETIME.getFullYear(), CHART_START_DATETIME.getMonth(), 1);
  const new_start_date = firstDayStart.setDate((firstDayStart.getDate()) + new_start_of_day);
  const new_end_date = firstDayEnd.setDate((firstDayEnd.getDate() - 1) + new_end_of_day);

  return [new Date(new_start_date), new Date(new_end_date)];

  // target_element.style.left = ((closest_day * CHART_DAY_WIDTH) - ((CHART_START_DATETIME.getDate() - 1) * CHART_DAY_WIDTH)) + "px"
}

function handleClickedComponent(e) {

}

function getPos(el, type) {
  for (var lx = 0, ly = 0;
    el != null;

    lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
  return { x: lx, y: ly };
}

function getElementCenterPosition(el, type) {
  const { width } = el.getBoundingClientRect();
  const { height } = el.getBoundingClientRect();

  const elPosition = getOffset(el);
  const centerX = elPosition.left + width / 2;
  const centerY = elPosition.top + height / 2;

  return { x: elPosition.left + centerX, y: elPosition.top + centerY };
}

function getClosestRigRowIDOnDragEnd(element) {
  const elementCenter = getElementCenterPosition(element, 'DRAGGED');

  for (let x = 0; x < routeRowIDs.length; x++) {
    const routeRowID = routeRowIDs[x];
    const routeRowElement = document.getElementById(routeRowID)!;
    const routeRowMinY = getElementCenterPosition(routeRowElement, 'ROUTE').y - ((routeRowElement.getBoundingClientRect().height / 2) + 35);
    const routeRowMaxY = getElementCenterPosition(routeRowElement, 'ROUTE').y + ((routeRowElement.getBoundingClientRect().height / 2) + 115);

    if (elementCenter.y > routeRowMinY && elementCenter.y < routeRowMaxY) {
      return routeRowID;
    } if (elementCenter.y <= routeRowMinY && x == 0) {
      return routeRowID;
    } if (elementCenter.y >= routeRowMaxY && x == (routeRowIDs.length - 1)) {
      return routeRowID;
    }
  }
}

